import { Text } from "@app/components/questkit/text";
import React, {
  type PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { useTheme } from "styled-components/native";
import Icon, { IconIdentifier } from "@app/components/icon";
import { Dimensions, FlatList, View, ViewStyle } from "react-native";
import { StyledProps } from "styled-components";
import { TouchBoundary } from "@app/util/TouchBoundary";
import { Portal } from "@gorhom/portal";
import { useSideBarContext } from "@app/navigation/sideBar/SideBarProvider";
import { useEffectOnce } from "@app/util/useEffectOnce";
import Animated, {
  measure,
  MeasuredDimensions,
  runOnJS,
  runOnUI,
  SharedValue,
  useAnimatedReaction,
  useAnimatedRef,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import QKScrollView, {
  useScrollViewController,
} from "@app/components/questkit/ScrollView";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import type { ListRenderItemInfo } from "@react-native/virtualized-lists/Lists/VirtualizedList";
import isEqual from "react-fast-compare";
import type {
  BaseListItem,
  ListViewModel,
} from "@app/components/questkit/dropdown/useListViewModel";

// export type DropdownOption<T = unknown> = {
//   label: string;
//   value: T;
//   icon?: IconIdentifier;
// };

export type DropdownTheme = {
  primary: string;
  background: string;
  groupHeaderTextColor?: string;
};

// export type OptionGroup<T = unknown> = {
//   label: string;
//   options: DropdownOption<T>[];
// };

// function isOptionGroup<T>(
//   optionOrOptionGroup: OptionGroup<T> | DropdownOption<T>
// ): optionOrOptionGroup is OptionGroup<T> {
//   return "options" in optionOrOptionGroup && !("value" in optionOrOptionGroup);
// }

// export type DropdownOptionListItem<T = unknown> =
//   | DropdownOption<T>
//   | OptionGroup<T>;

export interface DropdownSelectionRendererProps<ITEM extends BaseListItem> {
  value: ITEM["value"] | undefined;
  item: ITEM | undefined;
  // onPress: () => void;
  isOpen: boolean;
  loadingOptions: boolean;
  optionNoun: string;
  optionPluralNoun: string;
  optionsAreAvailable: boolean;
  inlineStyle: boolean;
  theme: DropdownTheme;
  readOnly: boolean;
  placeholderTextMap: PlaceholderTextProviderMap;
  placeholderIcon?: IconIdentifier;
  // parentTestID?: string;
}

export type DropdownSelectionRenderer<ITEM extends BaseListItem> = (
  props: DropdownSelectionRendererProps<ITEM>
) => React.ReactElement | null;

export interface InlineDropdownProps<ITEM extends BaseListItem> {
  value?: ITEM["value"];
  options: ListViewModel<ITEM>;
  SelectionRenderer: DropdownSelectionRenderer<ITEM>;
  OptionRenderer: ListItemRenderer<ITEM>;
  loadingOptions: boolean;
  optionNoun: string;
  optionPluralNoun: string;
  /**
   * Called when the user selects an option from the list
   * or clears the selected option.
   * When clearing the selected option the provided value will be `undefined`
   */
  onSelect: (option: ITEM | undefined) => void;
  onCreate?: () => void;
  placeholderIcon?: IconIdentifier;
  placeholderTextOverridesMap?: Partial<PlaceholderTextProviderMap>;
  themeOverride?: DropdownTheme;
  inlineStyle?: boolean;
  readOnly?: boolean;
  style?: StyledProps<ViewStyle>;
  testID?: string;
}

export const InlineDropdown = <ITEM extends BaseListItem>({
  testID,
  value,
  options,
  SelectionRenderer,
  OptionRenderer,
  loadingOptions,
  optionNoun,
  optionPluralNoun,
  onSelect: _onSelect,
  onCreate,
  placeholderIcon,
  themeOverride,
  style,
  readOnly = false,
  inlineStyle = false,
  placeholderTextOverridesMap,
}: InlineDropdownProps<ITEM>): ReactElement => {
  const _theme = useTheme();
  const theme = themeOverride || _theme;

  const [isOpen, setIsOpen] = useState(false);
  const { values, useItem } = options;

  // const allOptions = useMemo(() => {
  //   return options.flatMap((optionOrOptionGroup) =>
  //     isOptionGroup(optionOrOptionGroup)
  //       ? optionOrOptionGroup.options
  //       : [optionOrOptionGroup]
  //   );
  // }, [options]);
  //
  // let option: DropdownOption<T> | undefined = allOptions.find(
  //   ({ value }) => value === selectedOption
  // );

  const optionsAreAvailable = Array.isArray(values) && values.length > 0;

  const optionsAreNotAvailableButAreLoading =
    loadingOptions && !optionsAreAvailable;

  // if (!option) {
  //   const placeholderText = {
  //     ...defaultPlaceholderTextProviderMap,
  //     ...placeholderTextOverridesMap,
  //   }[
  //     optionsNotAvailable
  //       ? "OPTIONS_LOADING"
  //       : selectedOption
  //       ? "SELECTED_OPTION_NOT_AVAILABLE"
  //       : Array.isArray(options) && options.length > 0
  //       ? "OPTIONS_AVAILABLE"
  //       : "OPTIONS_NOT_AVAILABLE"
  //   ]({
  //     optionPluralNoun,
  //     optionNoun,
  //   });
  //   option = {
  //     label: placeholderText,
  //     value: null as T,
  //     icon: placeholderIcon,
  //   };
  // }

  const onCreateNew = useCallback(() => {
    if (readOnly) {
      return;
    }
    onCreate?.();
    setIsOpen(false);
  }, [readOnly, onCreate]);
  // const dropdownItems = useMemo(() => {
  //   const flatMap = (options || []).flatMap<DropdownItem<T>>(
  //     (optionOrGroup) => {
  //       if (isOptionGroup(optionOrGroup)) {
  //         return [
  //           {
  //             label: optionOrGroup.label,
  //             type: "GROUP_LABEL" as const,
  //           },
  //           ...optionOrGroup.options
  //             .map((option) => ({
  //               ...option,
  //               type: "GROUP_OPTION" as const,
  //             }))
  //             .sort((a, b) => a.label.localeCompare(b.label, undefined, {})),
  //         ];
  //       } else {
  //         return [{ ...optionOrGroup, type: "OPTION" as const }];
  //       }
  //     }
  //   );
  //   return (
  //     flatMap
  //       // TODO: Revisit how sorting works... is this a responsibility of the caller?
  //       //       What about when searching (modal dropdown only though...)
  //       // .filter((optionOrGroup) => isOptionGroup(optionOrGroup) || option!.value !== optionOrGroup.value)
  //       // .sort((a, b) => a.label.localeCompare(b.label, undefined, {}))
  //       .map((optionOrGroupLabel) => {
  //         if (optionOrGroupLabel.type === "GROUP_LABEL") {
  //           const { label } = optionOrGroupLabel;
  //
  //           return (
  //             <DropdownListGroupHeader
  //               key={`option-group-label-${label}`}
  //               label={label}
  //               ddTheme={theme}
  //             />
  //           );
  //         } else {
  //           const { type, value, label, icon } = optionOrGroupLabel;
  //           return (
  //             <DropdownListOption
  //               testID={testID ? `${testID}-option-${value}` : undefined}
  //               label={label}
  //               icon={icon}
  //               onSelect={() => {
  //                 onSelect(value);
  //                 setIsOpen(false);
  //               }}
  //               ddTheme={theme}
  //               key={String(value)}
  //               indented={type === "GROUP_OPTION"}
  //             />
  //           );
  //         }
  //       })
  //   );
  // }, [onSelect, options, testID, theme]);

  const onSelect = useCallback(
    (item: ITEM) => {
      _onSelect(item);
      setIsOpen(false);
    },
    [_onSelect]
  );

  const dropdownContainerRef = useAnimatedRef<View>();
  const [isListMounted, setListMounted] = useState(false);
  const layout = useSharedValue({
    pageX: 0,
    pageY: 0,
    width: 0,
    height: 0,
  });

  const { drawerSlideAnimation } = useSideBarContext();
  const updateScreenPosition = useCallback(() => {
    return new Promise<void>((resolve) => {
      runOnUI(() => {
        const result = measure(dropdownContainerRef);
        if (result) {
          layout.value = result;
          runOnJS(resolve)();
        }
      })();
    });
  }, [dropdownContainerRef, layout]);

  const onPressSelectedOption = useCallback(() => {
    const newIsOpen = !isOpenToggleFixRef.current;
    if (readOnly || (newIsOpen && optionsAreNotAvailableButAreLoading)) {
      return;
    }
    void updateScreenPosition().then(() => setIsOpen(newIsOpen));
  }, [readOnly, optionsAreNotAvailableButAreLoading, updateScreenPosition]);

  const onTouchOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useAnimatedReaction(
    () => drawerSlideAnimation.value,
    () => {
      const result = measure(dropdownContainerRef);
      if (result) {
        layout.value = result;
      }
    },
    []
  );

  useEffectOnce(() => {
    const listener = Dimensions.addEventListener("change", () =>
      updateScreenPosition()
    );
    return () => {
      listener.remove();
    };
  });

  const nearestScrollViewController = useScrollViewController({
    okIfNotAvailable: true,
  });
  useEffectOnce(() => {
    const listener = () => updateScreenPosition();
    nearestScrollViewController?.addScrollOffsetListener(listener);
    return () => {
      nearestScrollViewController?.removeScrollOffsetListener(listener);
    };
  });

  const ItemOptionRenderer = useCallback(
    (props: ListRenderItemInfo<ITEM["value"]>) => {
      return (
        <ListItemWrapper
          value={props.item}
          useItem={useItem}
          ItemRenderer={OptionRenderer}
          onSelect={onSelect}
          isSelected={value === props.item}
          parentTestID={testID}
          {...props}
        />
      );
    },
    [useItem, onSelect, value, testID, OptionRenderer]
  );

  const isOpenToggleFixRef = useRef(isOpen);
  const onStartShouldSetResponderCapture = useCallback(() => {
    isOpenToggleFixRef.current = isOpen;
    return true;
  }, [isOpen]);
  const placeholderTextMap = useMemo(
    () => ({
      ...defaultPlaceholderTextProviderMap,
      ...placeholderTextOverridesMap,
    }),
    [placeholderTextOverridesMap]
  );
  return (
    <DropdownOuterContainer style={style} testID={testID}>
      <DropdownContainer
        ref={dropdownContainerRef}
        isOpen={isListMounted}
        ddTheme={theme}
        inlineStyle={inlineStyle}
        onLayout={updateScreenPosition}
      >
        <SelectedOption
          testID={testID ? `${testID}-selected-option` : undefined}
          onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
          onPress={onPressSelectedOption}
        >
          <SelectedOptionWrapper
            value={value}
            useItem={useItem}
            SelectionRenderer={SelectionRenderer}
            isOpen={isListMounted}
            loadingOptions={loadingOptions}
            optionNoun={optionNoun}
            optionPluralNoun={optionPluralNoun}
            inlineStyle={inlineStyle}
            theme={theme}
            readOnly={readOnly}
            optionsAreAvailable={optionsAreAvailable}
            placeholderIcon={placeholderIcon}
            placeholderTextMap={placeholderTextMap}
          />
        </SelectedOption>
      </DropdownContainer>
      {isOpen ? (
        <DropdownOptionsListContainer
          inlineStyle={inlineStyle}
          theme={theme}
          parentLayout={layout}
          onTouchOutside={onTouchOutside}
          setListMounted={setListMounted}
        >
          {optionsAreNotAvailableButAreLoading ? null : (
            <FlatList
              data={values}
              renderItem={ItemOptionRenderer}
              bounces={false}
            />
          )}
          {onCreate ? (
            <DropdownListOption
              label={`+ Create new ${optionNoun.toLowerCase()}`}
              onSelect={onCreateNew}
              ddTheme={theme}
            />
          ) : null}
        </DropdownOptionsListContainer>
      ) : null}
      <SpacerThatIsPresentWhenDropdownIsOpen />
    </DropdownOuterContainer>
  );
};

interface SelectedOptionWrapperProps<T extends BaseListItem>
  extends Omit<DropdownSelectionRendererProps<T>, "item"> {
  useItem: (value: undefined | T["value"]) => T | undefined;
  SelectionRenderer: DropdownSelectionRenderer<T>;
}

const SelectedOptionWrapper = <T extends BaseListItem>({
  value,
  useItem,
  SelectionRenderer,
  ...restProps
}: SelectedOptionWrapperProps<T>) => {
  const item = useItem(value);
  return <SelectionRenderer {...restProps} value={value} item={item} />;
};

interface DropdownOptionsListContainerProps extends PropsWithChildren {
  parentLayout: SharedValue<MeasuredDimensions>;
  inlineStyle: boolean;
  theme: DropdownTheme;
  onTouchOutside: () => void;
  setListMounted: (isListMounted: boolean) => void;
}
export const DropdownOptionsListContainer: React.FC<
  DropdownOptionsListContainerProps
> = ({
  parentLayout,
  inlineStyle,
  theme,
  onTouchOutside,
  children,
  setListMounted,
}) => {
  const safeAreaInsets = useSafeAreaInsets();

  const containerStyle = useAnimatedStyle(() => {
    return {
      width: parentLayout.value.width - (inlineStyle ? 0 : 2),
      top:
        parentLayout.value.pageY +
        parentLayout.value.height -
        (inlineStyle ? 0 : 2),
      left: parentLayout.value.pageX + (inlineStyle ? 0 : 1),
      bottom: 4 + safeAreaInsets.bottom,
    };
  }, [inlineStyle, safeAreaInsets]);

  return (
    <Portal
      hostName={"rootPortal"}
      handleOnMount={(onDone) => {
        setListMounted(true);
        onDone();
      }}
      handleOnUnmount={(onDone) => {
        setListMounted(false);
        onDone();
      }}
    >
      <DropdownListContainer style={containerStyle}>
        <StyledOptionsScrollView
          contentContainerStyle={{
            backgroundColor: theme.primary,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
          bounces={false}
          overScrollMode={"never"}
        >
          <TouchBoundary onTouchOutside={onTouchOutside}>
            {children}
          </TouchBoundary>
        </StyledOptionsScrollView>
      </DropdownListContainer>
    </Portal>
  );
};

const StyledOptionsScrollView = styled(QKScrollView)`
  background-color: transparent;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const DropdownListContainer = styled(Animated.View)`
  position: absolute;
`;

interface DropdownOptionProps {
  onSelect: () => void;
  label: string;
  icon?: IconIdentifier;
  ddTheme: DropdownTheme;
  indented?: boolean;
  testID?: string;
}

// type DropdownItem<T> =
//   | (Omit<OptionGroup<T>, "options"> & { type: "GROUP_LABEL" })
//   | (DropdownOption<T> & { type: "OPTION" | "GROUP_OPTION" });

const DropdownListOption: React.FC<DropdownOptionProps> = ({
  onSelect,
  label,
  icon,
  testID,
  indented,
  ddTheme,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);

  return (
    <DropdownOptionWrapper
      onPress={onSelect}
      testID={testID}
      isHovered={isHovered}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      indented={indented}
    >
      {icon ? <Icon inverted={true} icon={icon} offsetX={-1} /> : <IconDummy />}
      <DropdownOptionText ddTheme={ddTheme}>{label}</DropdownOptionText>
    </DropdownOptionWrapper>
  );
};

// const DropdownListGroupHeader: React.FC<{
//   label: string;
//   ddTheme: DropdownTheme;
// }> = ({ label, ddTheme }) => {
//   return (
//     <DropdownOptionWrapper disabled={true} indented={false}>
//       <IconDummy />
//       <DropdownOptionText
//         ddTheme={{
//           background: ddTheme.groupHeaderTextColor ?? ddTheme.background,
//         }}
//       >
//         {label}
//       </DropdownOptionText>
//     </DropdownOptionWrapper>
//   );
// };

const DropdownContainer = styled.View<{
  ddTheme: DropdownTheme;
  isOpen?: boolean;
  inlineStyle: boolean;
}>`
  background-color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.primary : ddTheme.background};
  flex-direction: column;
  min-height: 40px;
  border-color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.background : ddTheme.primary};
  border-width: ${({ inlineStyle }) => (inlineStyle ? "0" : "1px")};
  ${({ isOpen }) =>
    isOpen
      ? `
      border-top-left-radius: 20px; 
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0; 
      border-bottom-right-radius: 0;
      `
      : "border-radius: 20px;"};
  overflow: hidden;
`;

const SelectedOption = styled.Pressable`
  //flex-direction: row;
  //align-items: center;
`;

// const SelectedOptionText = styled(Text)<{
//   ddTheme: DropdownTheme;
//   isOpen: boolean;
// }>`
//   color: ${({ ddTheme, isOpen }) =>
//     isOpen ? ddTheme.background : ddTheme.primary};
//   flex: 1;
// `;

const DropdownOptionText = styled(Text)<{
  ddTheme: { background: string };
}>`
  color: ${({ ddTheme }) => ddTheme.background};
`;

// const DropdownOpenIndicator = styled(Icon)<{
//   ddTheme: DropdownTheme;
//   isOpen: boolean;
// }>`
//   color: ${({ ddTheme, isOpen }) =>
//     isOpen ? ddTheme.background : ddTheme.primary};
// `;

// const DropdownOpenIndicatorContainer = styled.View`
//   width: 40px;
//   height: 40px;
//   align-items: center;
//   justify-content: center;
// `;

const SpacerThatIsPresentWhenDropdownIsOpen = styled.View`
  height: 20px;
`;

const DropdownOuterContainer = styled.View`
  //height: 50px;
  user-select: none;
  margin-bottom: 10px;
`;

const DropdownOptionWrapper = styled.Pressable<{
  isHovered?: boolean;
  indented?: boolean;
}>`
  ${({ isHovered }) => `
    opacity: ${isHovered ? 0.8 : 1}
  `};
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ indented }) =>
    indented
      ? `
    padding-left: 20px;
  `
      : ""}
`;
const IconDummy = styled.View`
  width: 18px;
`;

type PlaceholderState =
  | "OPTIONS_AVAILABLE"
  | "OPTIONS_LOADING"
  | "OPTIONS_NOT_AVAILABLE"
  | "SELECTED_OPTION_NOT_AVAILABLE";
export type PlaceholderTextProviderMap = Record<
  PlaceholderState,
  (args: { optionPluralNoun: string; optionNoun: string }) => string
>;
const defaultPlaceholderTextProviderMap = {
  OPTIONS_LOADING: ({ optionPluralNoun }) =>
    `Loading ${optionPluralNoun.toLowerCase()}...`,
  SELECTED_OPTION_NOT_AVAILABLE: ({ optionNoun }) =>
    `Selected ${optionNoun.toLowerCase()} not available`,
  OPTIONS_NOT_AVAILABLE: ({ optionPluralNoun }) =>
    `No ${optionPluralNoun.toLowerCase()} available.`,
  OPTIONS_AVAILABLE: ({ optionNoun }) => `Select ${optionNoun.toLowerCase()}`,
} satisfies PlaceholderTextProviderMap;

// type ListViewModelOptions<ITEM extends BaseListItem> = {
//   keyExtractor?: (item: ITEM) => string;
// } & (ITEM extends StandardListItem
//   ? { ItemRenderer?: ListItemRenderer<ITEM> }
//   : { ItemRenderer: ListItemRenderer<ITEM> });

export interface ListItemRendererProps<ITEM extends BaseListItem>
  extends ListRenderItemInfo<ITEM> {
  onSelect: () => void;
  isSelected: boolean;
  parentTestID?: string;
}

export type ListItemRenderer<ITEM extends BaseListItem> = (
  props: ListItemRendererProps<ITEM>
) => React.ReactElement | null;

// const defaultKeyExtractor = (item: BaseListItem) => String(item.value);

type ListItemWrapper<ITEM extends BaseListItem> = (
  props: ListItemWrapperProps<ITEM>
) => React.ReactElement | null;

interface ListItemWrapperProps<ITEM extends BaseListItem>
  extends Omit<ListRenderItemInfo<ITEM["value"]>, "item"> {
  value: ITEM["value"];
  ItemRenderer: ListItemRenderer<ITEM>;
  useItem: (value: ITEM["value"]) => ITEM | undefined;
  onSelect: (item?: ITEM) => void;
  isSelected: boolean;
  parentTestID?: string;
}

const ListItemWrapper = React.memo(
  <ITEM extends BaseListItem>({
    value,
    useItem,
    ItemRenderer,
    onSelect: _onSelect,
    ...restProps
  }: ListItemWrapperProps<ITEM>) => {
    const item = useItem(value);
    const onSelect = useCallback(() => {
      _onSelect(item!);
    }, [_onSelect, item]);

    if (!item) {
      return null;
    }

    return <ItemRenderer {...restProps} onSelect={onSelect} item={item} />;
  },
  isEqual
);
ListItemWrapper.displayName = "ListItemWrapper";
