import React, { useEffect, useMemo, useState } from "react";

import { QuestInstanceScreen } from "@app/screens/quest/questInstance";
import { QuestPublicAssignmentScreen } from "@app/screens/quest/questPublicAssignment";
import { TemplateCreate } from "@app/screens/quest/templateCreate";
import { QuestCreate } from "@app/screens/quest/questCreate";
import { AssignmentsScreen } from "@app/screens/assignments";
import { CreateQuestLoadingScreen } from "@app/screens/quest/createQuestLoadingScreen";
import { HomeScreen } from "@app/screens/home";
import styled, { useTheme } from "styled-components/native";
import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import { FiltersProps } from "@app/components/filters";
import { BackButton } from "@app/navigation/components/BackButton";
import { getDefaultScreenOptions } from "@app/navigation/defaultScreenOptions";
import { OpenSideBarButton } from "@app/navigation/components/OpenSideBarButton";
import { SettingsScreen } from "@app/screens/settings";
import { AccountScreen } from "@app/screens/settings/account";
import { AppsScreen } from "@app/screens/settings/apps";
import { AppScreen } from "@app/screens/settings/app";
import { BillingScreen } from "@app/screens/settings/billing";
import { SupportScreen } from "@app/screens/settings/support";
import { WorkspaceSettingsScreen } from "@app/screens/settings/workspace";
import { IconList } from "@app/components/screen/iconList";
import { TemplateNewScreen } from "@app/screens/quest/templateNew";
import { PublicTemplatePreviewScreen } from "@app/screens/quest/PublicTemplatePreviewScreen";
import {
  CommonActions,
  Route,
  RouteProp,
  StackNavigationState,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { store, useAppSelector } from "@app/store";
import { LoginScreen } from "@app/screens/login";
import { useDispatch } from "react-redux";
import { removePostLoginTargetUrl, setPostLoginTargetUrl } from "@app/store/UI";
import linkingConfig, { getPathForScreen } from "@app/navigation/linkingConfig";
import { PartialState } from "@react-navigation/routers/src/types";
import { PlaceholderHeaderIcon } from "@app/navigation/components/HeaderIcon";
import { selectLoggedInUserId } from "@app/store/auth";
import { Platform } from "react-native";
import { useEffectOnce } from "@app/util/useEffectOnce";
import {
  MainQuestScreen,
  type QMTabNavigationProp,
  QuestTabsParamList,
} from "@app/quest/MainQuestScreen";
import { AdminQuestRunScreen } from "@app/screens/quest/AdminQuestRunScreen";
import { LogoutScreen } from "@app/screens/LogoutScreen";
import Loader from "@app/components/animated/loader";
import { AppDebugInfo } from "@app/screens/settings/AppDebugInfo";
import { sentry } from "@app/util/sentry";
import { RedirectScreen } from "@app/screens/RedirectScreen";
import { TeamSettingsScreen } from "@app/screens/settings/team";
import { ENV } from "@app/config/env";
import { HiddenOpenDebugScreenButton } from "@app/navigation/components/HiddenOpenDebugScreenButton";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import { QuestListScreen } from "@app/screens/quest/QuestListScreen";

const TEAMS_ENABLED = ENV.featureFlags.enableTeams;

export type QMStackParamList = {
  /**
   * Shared Screens
   */
  QuestInstance: {
    id: string;
    publicId?: string;
    loginId?: string;
    completionRedirect?: string;
    preventBack?: boolean;
  };
  PublicAssignment: { id: string };
  Redirect: { url: string };
  Logout: undefined;
  AppDebugInformation: undefined;

  /**
   * Logged-out only screens
   */
  Login?: {
    url?: string;
    loginId?: string;
    /**
     * Force a specific Auth0 connection to be used. This is useful for testing.
     */
    forceConnection?: "email" | "sms" | string;
    /**
     * Used by Auth0 Universal Login when redirecting back to the app after login.
     */
    code?: string;
    /**
     * Used by Auth0 Universal Login when redirecting back to the app after login.
     */
    state?: string;
  };

  /**
   * Logged-in only screens
   */
  Home: undefined;
  AdminQuestRun: {
    templateId: string;
    questInstanceId: string;
    loginId?: string;
  };
  QuestCreate: { templateId: string; workspaceId?: string };
  CreateQuestLoading: {
    questInstanceId: string;
    createQuestActionId: string;
  };
  Assignments: {
    filters: FiltersProps["activeFilters"];
  };
  PublicTemplatePreview: {
    questPrototypeId: string;
    screenshot?: boolean;
    status?:
      | "purchase-template-success"
      | "purchase-template-cancelled"
      | "purchase-subscription-success";
    action?: "use-quest";
  };
  TemplateNew: undefined;
  TemplateCreate: {
    sourceTemplateId?: string;
    sourcePrototypeId?: string;
    workspaceId?: string;
    name?: string;
  };
  Quest: {
    questId: string;
    advancedMode?: boolean;
  } & {
    [K in keyof QuestTabsParamList]: {
      screen: K;
      params?: QuestTabsParamList[K];
    };
  }[keyof QuestTabsParamList];
  QuestList: {
    filters: FiltersProps["activeFilters"];
  };

  Settings: undefined;
  Account: undefined;
  Support: undefined;
  Billing?: {
    returnUrl?: string;
    sessionStatus?: "SUCCESS" | "CANCELLED";
    sessionId?: string;
    coupon?: string;
  };
  Workspaces: undefined;
  Workspace: { workspaceId: string };
  Team: { teamId: string };
  Apps: undefined;
  App: { appId: string };
  IconList: undefined;
};

export type CombinedRoutesParamList = QMStackParamList & QuestTabsParamList;

export type QMRoute = {
  [K in keyof CombinedRoutesParamList]: Route<K, CombinedRoutesParamList[K]>;
}[keyof CombinedRoutesParamList];

export type QMNavigationHelpersForRoute<
  T extends keyof CombinedRoutesParamList
> = T extends keyof QMStackParamList
  ? StackNavigationProp<QMStackParamList, T>
  : T extends keyof QuestTabsParamList
  ? QMTabNavigationProp<T>
  : never;

export type QMNavigation<T extends keyof CombinedRoutesParamList = "Home"> =
  QMNavigationHelpersForRoute<T> & {
    navigateToLoginThenRedirectBackHere: (loginId?: string) => void;
    navigateToLoginThenRedirectTo: <RouteName extends keyof QMStackParamList>(
      ...args: undefined extends QMStackParamList[RouteName]
        ?
            | [screen: RouteName]
            | [screen: RouteName, params: QMStackParamList[RouteName]]
        : [screen: RouteName, params: QMStackParamList[RouteName]]
    ) => void;
  };
export const useAppNavigation = <
  T extends keyof CombinedRoutesParamList = "Home"
>(): QMNavigation<T> => {
  const navigation = useNavigation<QMNavigationHelpersForRoute<T>>();

  const rootStackNavigation = (navigation.getParent() ??
    navigation) as StackNavigationProp<
    QMStackParamList,
    keyof QMStackParamList
  >;

  return useMemo(() => {
    return {
      ...navigation,
      navigateToLoginThenRedirectBackHere: () => {
        const currentState = navigation.getState();
        const pathFromState = linkingConfig.getPathFromState(currentState);

        sentry.addBreadcrumb({
          message: "Navigating to login screen then back here.",
          data: {
            pathFromState,
            currentState,
          },
        });

        rootStackNavigation.push("Login", {
          url: pathFromState,
        });
      },
      navigateToLoginThenRedirectTo: (
        screenName: string,
        screenParams?: Record<string, unknown>
      ) => {
        const postLoginTargetUrl = getPathForScreen(
          {
            screen: screenName,
            params: screenParams,
          },
          {
            pathConfigurationOverride: "LOGGED_IN",
          }
        );

        sentry.addBreadcrumb({
          message: "Navigating to login screen then redirecting.",
          data: {
            redirectScreenName: screenName,
            redirectScreenParams: screenParams,
            postLoginTargetUrl,
          },
        });

        if (postLoginTargetUrl) {
          store.dispatch(setPostLoginTargetUrl(postLoginTargetUrl));
        } else {
          sentry.captureMessage(
            "Failed to create postLoginTargetUrl when requiring user to login and redirect back.",
            {
              extra: {
                screenName,
                screenParams,
                postLoginTargetUrl,
              },
            }
          );
        }
        rootStackNavigation.push(
          "Login",
          postLoginTargetUrl ? { url: postLoginTargetUrl } : {}
        );
      },
    };
  }, [navigation, rootStackNavigation]);
};

export const useAppRoute = <
  T extends keyof CombinedRoutesParamList
>(): RouteProp<CombinedRoutesParamList, T> => {
  return useRoute<RouteProp<CombinedRoutesParamList, T>>();
};

/**
 * Can be used outside a screen unlike `useAppRoute`. Use `useAppRoute` if possible since it is simpler.
 */
export const useFocusedRoute = (): QMRoute | undefined => {
  const [focusedRoute, setFocusedRoute] = useState<QMRoute | undefined>(() =>
    navigationRef.isReady()
      ? (navigationRef.getCurrentRoute() as QMRoute | undefined)
      : undefined
  );
  useEffectOnce(() => {
    const stateListener = () => {
      setFocusedRoute(navigationRef.getCurrentRoute() as QMRoute | undefined);
    };
    navigationRef.addListener("state", stateListener);
    return () => navigationRef.removeListener("state", stateListener);
  });

  return focusedRoute;
};

const Stack = createStackNavigator<QMStackParamList>();
export const QMNavigator: React.FC = () => {
  const navigation = useAppNavigation<keyof QMStackParamList>();
  const dispatch = useDispatch();
  const currentUserId = useAppSelector(selectLoggedInUserId);
  const previousUserIdRef = React.useRef(currentUserId);
  const postLoginTargetUrl = useAppSelector(
    (state) => state.ui.postLoginTargetUrl
  );
  const isLoggingOut = useAppSelector((state) => state.ui.loggingOut);

  useEffect(() => {
    const previousUserId = previousUserIdRef.current;
    previousUserIdRef.current = currentUserId;

    if (previousUserId !== currentUserId) {
      const userJustLoggedIn =
        previousUserId === null && currentUserId !== null;
      if (userJustLoggedIn) {
        // when logging in, take the user to the target url if there is one
        let stateHasBeenReset = false;
        if (postLoginTargetUrl) {
          dispatch(removePostLoginTargetUrl());

          const targetState =
            linkingConfig.getStateFromPath(postLoginTargetUrl);
          if (targetState) {
            sentry.addBreadcrumb({
              message: "Navigating to post login target url",
              data: { postLoginTargetUrl, targetState },
            });
            setTimeout(() => {
              navigation.reset(
                targetState as PartialState<
                  StackNavigationState<QMStackParamList>
                >
              );
            }, 1);
            stateHasBeenReset = true;
          } else {
            sentry.captureMessage("Failed to parse post login target url", {
              extra: { postLoginTargetUrl, targetState },
            });
          }
        }

        // This fixes an issue where the user logged out, then back in, and were taken
        // to the settings page instead of the home page.
        const navigationState = navigation.getState();
        const loggedInNavigatorHasHistoryFromPreviousLoggedInState =
          (navigationState?.history?.length ?? 0) > 0;
        if (
          !stateHasBeenReset &&
          loggedInNavigatorHasHistoryFromPreviousLoggedInState
        ) {
          sentry.addBreadcrumb({
            message: "Resetting navigator state to clear history.",
            data: {
              postLoginTargetUrl,
              previousNavigationState: navigationState,
            },
          });
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Home" }],
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const theme = useTheme();
  const userIsLoggedIn = currentUserId !== null;

  return (
    <>
      <Stack.Navigator
        screenOptions={getDefaultScreenOptions(theme, userIsLoggedIn)}
      >
        {/* initial screen  */}
        {userIsLoggedIn ? (
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            navigationKey={currentUserId}
            options={() => ({
              title: "Home",
              headerLeft: OpenSideBarButton,
            })}
          />
        ) : (
          <Stack.Screen
            name="Login"
            component={LoginScreen}
            options={() => ({
              headerTitleStyle: { display: "none" },
              headerShown: true,
              headerRight: HiddenOpenDebugScreenButton,
            })}
          />
        )}

        <Stack.Screen
          /*  no navigationKey allows this screen to be used while transitioning from logged-in to logged-out */
          name="Logout"
          component={LogoutScreen}
          options={{
            headerShown: false,
            animationEnabled: false,
            gestureEnabled: false,
            detachPreviousScreen: true,
          }}
        />

        {/* shared screens  */}
        <Stack.Group navigationKey={String(currentUserId)}>
          <Stack.Screen
            name="Redirect"
            component={RedirectScreen}
            options={() => ({
              title: "...",
              headerShown: false,
              headerLeft: PlaceholderHeaderIcon,
            })}
          />
          <Stack.Screen
            name="QuestInstance"
            component={QuestInstanceScreen}
            options={() => ({
              title: "Quest",
              headerTitle: "",
              headerTransparent: true,
              headerShown: true,
              headerLeft: userIsLoggedIn ? BackButton : PlaceholderHeaderIcon,
            })}
          />
          <Stack.Screen
            name="PublicAssignment"
            component={QuestPublicAssignmentScreen}
            options={() => ({
              title: "Preparing Quest...",
              headerTitle: "",
              headerTransparent: true,
              headerLeft: userIsLoggedIn ? BackButton : PlaceholderHeaderIcon,
            })}
          />
          <Stack.Screen
            name="PublicTemplatePreview"
            component={PublicTemplatePreviewScreen}
            options={({ route }) => ({
              headerShown: !(route && route.params && route.params.screenshot),
              headerTitle: "",
              // headerTitle: "Quest Preview",
              // headerTitleStyle: {
              //   color: theme.warning,
              // },
            })}
          />
          <Stack.Screen
            name="AppDebugInformation"
            component={AppDebugInfo}
            options={() => ({
              title: "App Debug Information",
              headerLeft: BackButton,
              headerShown: true,
            })}
          />
        </Stack.Group>

        {/* logged-in screens  */}
        {userIsLoggedIn && (
          <Stack.Group navigationKey={String(currentUserId)}>
            <Stack.Screen
              name="CreateQuestLoading"
              component={CreateQuestLoadingScreen}
              options={() => ({
                headerTransparent: true,
                title: "",
              })}
            />
            <Stack.Screen
              name="Quest"
              component={MainQuestScreen}
              options={{
                // allowing this screen to `freezeOnBlur` caused strange behavior like preventing the tabs from switching.
                freezeOnBlur: false,
                headerLeft: OpenSideBarButton,
                title: "",
              }}
            />
            <Stack.Screen
              name="TemplateCreate"
              component={TemplateCreate}
              options={() => ({
                headerTransparent: true,
                title: "",
              })}
            />
            <Stack.Screen
              name="TemplateNew"
              component={TemplateNewScreen}
              options={() => ({
                title: "Create New Quest",
              })}
            />
            <Stack.Screen
              name="QuestList"
              initialParams={{ filters: {} }}
              component={QuestListScreen}
              options={() => ({
                title: "Quests",
                headerLeft: OpenSideBarButton,
              })}
            />
            <Stack.Screen
              name="AdminQuestRun"
              component={AdminQuestRunScreen}
              options={() => ({
                headerTransparent: true,
                title: "",
              })}
            />
            <Stack.Screen
              name="QuestCreate"
              component={QuestCreate}
              options={() => ({
                headerTransparent: true,
                title: "",
              })}
            />
            <Stack.Screen
              name="Assignments"
              initialParams={{ filters: {} }}
              component={AssignmentsScreen}
              options={() => ({
                headerLeft: OpenSideBarButton,
              })}
            />

            <Stack.Screen
              name="Settings"
              component={SettingsScreen}
              options={() => ({
                headerTransparent: true,
                headerLeft: OpenSideBarButton,
              })}
            />
            <Stack.Screen name="Account" component={AccountScreen} />
            <Stack.Screen name="Apps" component={AppsScreen} />
            <Stack.Screen name="App" component={AppScreen} />
            {Platform.OS === "web" && (
              /**
               * In addition to preventing users from seeing the "Subscription & Billing" link in settings, we should
               * also prevent the route from being registered at all to avoid showing it if opened via deep-link. If this
               * screen is presented and functional to users in the mobile app we will be banned from the app stores!
               */
              <Stack.Screen name="Billing" component={BillingScreen} />
            )}
            <Stack.Screen
              name="Support"
              component={SupportScreen}
              options={() => ({
                title: "Support & Feedback",
              })}
            />
            <Stack.Screen
              name="Workspace"
              component={WorkspaceSettingsScreen}
            />
            {TEAMS_ENABLED ? (
              <Stack.Screen name="Team" component={TeamSettingsScreen} />
            ) : null}

            <Stack.Screen
              name="IconList"
              component={IconList}
              options={{ headerShown: false }}
            />
          </Stack.Group>
        )}
      </Stack.Navigator>
      {isLoggingOut ? (
        // In order to get the navigation state correct, multiple navigations are required during logout.
        // To hide the multiple screen transitions we put an overlay over the app until we reach the final screen to
        // show to the user.
        <LogoutOverlay>
          <Loader />
        </LogoutOverlay>
      ) : null}
    </>
  );
};

const LogoutOverlay = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.background};
  align-items: center;
  justify-content: center;
`;
