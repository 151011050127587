import React, { useCallback, useMemo } from "react";
import {
  type BaseListItem,
  useListViewModel,
} from "@app/components/questkit/dropdown/useListViewModel";
import Icon, { IconIdentifier } from "@app/components/icon";
import { useHover } from "@app/util/useHover";
import styled, { useTheme } from "styled-components/native";
import Text from "@app/components/questkit/text";
import {
  type DropdownSelectionRendererProps,
  type DropdownTheme,
  InlineDropdown,
  type InlineDropdownProps,
  type ListItemRendererProps,
} from "@app/components/questkit/dropdown/inlineDropdown";

export interface StandardDropdownListItem<VALUE = unknown>
  extends BaseListItem<VALUE> {
  label: string;
  icon?: IconIdentifier;
}

interface StandardInlineDropdownProps<ITEM extends StandardDropdownListItem>
  extends Omit<
    InlineDropdownProps<ITEM>,
    "SelectionRenderer" | "OptionRenderer" | "options"
  > {
  options: readonly ITEM[];
}

export const StandardInlineDropdown = <ITEM extends StandardDropdownListItem>(
  props: StandardInlineDropdownProps<ITEM>
) => {
  const defaultTheme = useTheme();
  const theme = props.themeOverride ?? defaultTheme;
  const OptionRenderer = useCallback(
    (props: ListItemRendererProps<ITEM>) => (
      <StandardDropdownOptionRenderer {...props} theme={theme} />
    ),
    [theme]
  );

  const options = useListViewModel(props.options);

  return (
    <InlineDropdown
      {...props}
      options={options}
      OptionRenderer={OptionRenderer}
      SelectionRenderer={StandardDropdownSelectionRenderer}
    />
  );
};

interface StandardDropdownListItemRendererProps<
  ITEM extends StandardDropdownListItem
> extends ListItemRendererProps<ITEM> {
  theme: DropdownTheme;
  indented?: boolean;
}

const StandardDropdownOptionRenderer = <ITEM extends StandardDropdownListItem>({
  onSelect,
  item,
  parentTestID,
  theme,
  indented,
}: StandardDropdownListItemRendererProps<ITEM>) => {
  const { hoverProps } = useHover();

  const value = item.value;
  const testID = useMemo(() => {
    return parentTestID ? `${parentTestID}-option-${value}` : undefined;
  }, [parentTestID, value]);

  return (
    <DropdownOptionWrapper
      onPress={onSelect}
      testID={testID}
      {...hoverProps}
      indented={indented}
    >
      {item.icon ? (
        <Icon inverted={true} icon={item.icon} offsetX={-1} />
      ) : (
        <IconDummy />
      )}
      <DropdownOptionText ddTheme={theme}>{item.label}</DropdownOptionText>
    </DropdownOptionWrapper>
  );
};

export const StandardDropdownSelectionRenderer = <
  ITEM extends StandardDropdownListItem
>({
  value,
  item: _item,
  isOpen,
  loadingOptions,
  optionNoun,
  optionPluralNoun,
  optionsAreAvailable,
  inlineStyle,
  theme,
  readOnly,
  placeholderIcon,
  placeholderTextMap,
}: DropdownSelectionRendererProps<ITEM>) => {
  let item = _item;
  if (!item) {
    const placeholderText = placeholderTextMap[
      loadingOptions && !optionsAreAvailable
        ? "OPTIONS_LOADING"
        : value
        ? "SELECTED_OPTION_NOT_AVAILABLE"
        : optionsAreAvailable
        ? "OPTIONS_AVAILABLE"
        : "OPTIONS_NOT_AVAILABLE"
    ]({
      optionPluralNoun,
      optionNoun,
    });
    item = {
      label: placeholderText,
      value: undefined,
      icon: placeholderIcon,
    } as ITEM;
  }
  const showDropdownOpenIndicator =
    !readOnly && (!loadingOptions || optionsAreAvailable);
  return (
    <SelectionContainer>
      {item.icon ? (
        <Icon inverted={isOpen} icon={item.icon} offsetX={-1} />
      ) : (
        <IconDummy />
      )}
      <SelectedOptionText
        isOpen={isOpen}
        numberOfLines={1}
        ddTheme={theme}
        size={inlineStyle ? "mediumBold" : "medium"}
      >
        {item.label}
      </SelectedOptionText>
      <DropdownOpenIndicatorContainer>
        {showDropdownOpenIndicator ? (
          <DropdownOpenIndicator
            isOpen={isOpen}
            icon={isOpen ? "chevron-up" : "chevron-down"}
            size={32}
            ddTheme={theme}
          />
        ) : null}
      </DropdownOpenIndicatorContainer>
    </SelectionContainer>
  );
};

const SelectionContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const IconDummy = styled.View`
  width: 18px;
`;

const DropdownOptionText = styled(Text)<{
  ddTheme: { background: string };
}>`
  color: ${({ ddTheme }) => ddTheme.background};
`;
const DropdownOptionWrapper = styled.Pressable<{
  isHovered?: boolean;
  indented?: boolean;
}>`
  ${({ isHovered }) => `
    opacity: ${isHovered ? 0.8 : 1}
  `};
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ indented }) =>
    indented
      ? `
    padding-left: 20px;
  `
      : ""}
`;

const SelectedOptionText = styled(Text)<{
  ddTheme: DropdownTheme;
  isOpen: boolean;
}>`
  color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.background : ddTheme.primary};
  flex: 1;
`;

const DropdownOpenIndicator = styled(Icon)<{
  ddTheme: DropdownTheme;
  isOpen: boolean;
}>`
  color: ${({ ddTheme, isOpen }) =>
    isOpen ? ddTheme.background : ddTheme.primary};
`;

const DropdownOpenIndicatorContainer = styled.View`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;
