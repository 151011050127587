import React, { useCallback, useMemo, useState } from "react";
import {
  StandardDropdownListItem,
  StandardInlineDropdown,
} from "@app/components/questkit/dropdown/StandardInlineDropdown";
import { fetchWorkspaces } from "@app/util/client/requests/workspaces";
import { useAppSelector } from "@app/store";
import { selectUsersWorkspaces } from "@app/store/cache/workspaces";
import isEqual from "react-fast-compare";
import { useRequest } from "@app/util/client/requests";

interface WorkspaceListProps {
  workspaceId: string | null;
  setWorkspaceId: (workspaceId: string | null) => void;
  allowCreate?: boolean;
}

export const NEW_WORKSPACE_ID = "NEW_WORKSPACE";
export const WorkspaceList: React.FC<WorkspaceListProps> = ({
  workspaceId,
  setWorkspaceId: _setWorkspaceId,
  allowCreate = false,
}) => {
  const { isValidating: workspacesIsValidating } = useRequest(
    fetchWorkspaces()
  );

  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);
  const workspaces = useAppSelector(
    (state) =>
      selectUsersWorkspaces(state).map((workspace) => {
        return {
          id: workspace!.id,
          name: workspace!.name,
        };
      }),
    isEqual
  );

  const workspaceOptions: StandardDropdownListItem<string | null>[] = useMemo(
    () => [
      {
        label: "Personal",
        value: null,
        icon: "person",
      },
      ...(workspaces?.map((workspace) => ({
        label: workspace.name,
        value: workspace.id,
        icon: "group" as const,
      })) || []),
      ...(isCreatingWorkspace
        ? [
            {
              label: "New Workspace",
              value: NEW_WORKSPACE_ID,
              icon: "group" as const,
            },
          ]
        : []),
    ],
    [workspaces, isCreatingWorkspace]
  );

  const onCreate = useCallback(() => {
    setIsCreatingWorkspace(true);
    _setWorkspaceId(NEW_WORKSPACE_ID);
  }, [_setWorkspaceId]);

  const onSelectWorkspace = useCallback(
    (selection?: StandardDropdownListItem<string | null>) => {
      const workspaceId = selection?.value;
      if (workspaceId !== NEW_WORKSPACE_ID) {
        setIsCreatingWorkspace(false);
      }
      _setWorkspaceId(workspaceId ?? null);
    },
    [_setWorkspaceId]
  );

  return (
    <StandardInlineDropdown
      options={workspaceOptions}
      value={workspaceId}
      onCreate={allowCreate ? onCreate : undefined}
      onSelect={onSelectWorkspace}
      loadingOptions={workspacesIsValidating}
      optionNoun="Workspace"
      optionPluralNoun="Workspaces"
    />
  );
};
