import { useCallback, useMemo } from "react";
import { useDataSelectorFactory } from "@app/util/useDataSelectorFactory";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";

export interface BaseListItem<VALUE = unknown> {
  value: VALUE;
}

export interface ListViewModel<ITEM extends BaseListItem> {
  values: ITEM["value"][];
  useItem: (value: ITEM["value"]) => ITEM | undefined;
}

export function useListViewModel<ITEM extends BaseListItem>(
  data: readonly ITEM[]
): ListViewModel<ITEM> {
  const structuredData = useMemo(() => {
    const values = [];
    const itemsByValueMap = new Map<ITEM["value"], ITEM>();
    for (let i = 0; i < data.length; i++) {
      values.push(data[i].value);
      itemsByValueMap.set(data[i].value, data[i]);
    }
    return {
      values,
      itemsByValueMap,
    };
  }, [data]);

  const { useDataSelector } = useDataSelectorFactory(
    structuredData.itemsByValueMap
  );
  const useItem = useCallback(
    (itemValue: undefined | ITEM["value"]) =>
      useDataSelector((itemMap) =>
        itemValue === undefined ? undefined : itemMap.get(itemValue)
      ),
    [useDataSelector]
  );

  const values = useIsEqualMemo(structuredData.values);

  return useMemo(
    () => ({
      values,
      useItem,
    }),
    [values, useItem]
  );
}
