import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import { Platform, View } from "react-native";
import {
  NEW_WORKSPACE_ID,
  WorkspaceList,
} from "@app/components/questkit/WorkspaceList";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { updateTemplates } from "@app/util/cacheMod";
import { useAppSelector } from "@app/store";
import {
  createQuest,
  fetchQuestPrototype,
} from "@app/util/client/requests/quests";
import { NEW_TEAM_ID, TeamList } from "@app/components/questkit/TeamList";
import { selectLoggedInUser } from "@app/store/cache/users";
import {
  selectUsersWorkspaces,
  selectWorkspaceById,
} from "@app/store/cache/workspaces";
import { usePromise } from "@app/util/usePromise";
import { sentry } from "@app/util/sentry";
import { createTeam } from "@app/util/client/requests/teams";
import { createWorkspace } from "@app/util/client/requests/workspaces";
import {
  CreateWorkspaceRequest,
  type QuestStartTriggerType,
} from "@questmate/openapi-spec";
import { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { ENV } from "@app/config/env";
import { updateQuestStartTrigger } from "@app/util/client/requests/questStartTriggers";
import { Analytics } from "@app/analytics";
import Text from "@app/components/questkit/text";
import {
  type StandardDropdownListItem,
  StandardDropdownSelectionRenderer,
} from "@app/components/questkit/dropdown/StandardInlineDropdown";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import {
  type DropdownSelectionRendererProps,
  InlineDropdown,
  type ListItemRendererProps,
} from "@app/components/questkit/dropdown/inlineDropdown";
import { useListViewModel } from "@app/components/questkit/dropdown/useListViewModel";
import { useHover } from "@app/util/useHover";
import { StartTriggerTypeListItem } from "@app/components/modal/createTemplate/QuestStartTriggerListItem";

interface CreateQuestProps {
  setShowModal: (showModal: boolean) => void;
  createButtonText: string;
  sourceTemplateId?: string;
  defaultName?: string;
  recommendedStartTriggerType?: QuestStartTriggerType;
}

const TEAMS_ENABLED = ENV.featureFlags.enableTeams;

const CreateQuest: React.FC<CreateQuestProps> = ({
  setShowModal,
  createButtonText,
  sourceTemplateId,
  defaultName,
  recommendedStartTriggerType,
}) => {
  const navigation = useAppNavigation();
  const initialWorkspaceId = useAppSelector(
    (state) => state.ui.sideBar.activeWorkspaceId
  );

  const snackbar = useContext(SnackbarContext);

  const [questName, setQuestName] = useState(defaultName || "");
  const [startTriggerType, setStartTriggerType] = useState<
    QuestStartTriggerType | undefined
  >(undefined);
  const [workspaceId, setWorkspaceId] = useState<string | null>(
    initialWorkspaceId
  );
  const hasMultipleWorkspaces = useAppSelector(
    // Personal is not part of this list.
    (state) => selectUsersWorkspaces(state).length > 0
  );

  const [questCreated, setQuestCreated] = useState(false);

  const { execute: onCreateQuest, isLoading } = usePromise(async () => {
    const createQuestFields = {
      name: questName,
      workspaceId,
      sourceTemplateId,
    };

    if (!createQuestFields.name.trim()) {
      snackbar.sendMessage(`Please enter a name.`, SnackbarSeverity.WARNING);
      return;
    } else if (!startTriggerType) {
      snackbar.sendMessage(
        `Please select a start trigger.`,
        SnackbarSeverity.WARNING
      );
      return;
    }
    if (TEAMS_ENABLED) {
      if (createQuestFields.workspaceId === NEW_WORKSPACE_ID) {
        if (!newWorkspaceName.trim()) {
          snackbar.sendMessage(
            `Please provide a name for the new workspace.`,
            SnackbarSeverity.WARNING
          );
          return;
        } else if (!teamIdForNewWorkspace) {
          snackbar.sendMessage(
            `Please select a team for the new workspace.`,
            SnackbarSeverity.WARNING
          );
          return;
        } else if (
          teamIdForNewWorkspace === NEW_TEAM_ID &&
          !newTeamName.trim()
        ) {
          snackbar.sendMessage(
            `Please provide a name for the new team.`,
            SnackbarSeverity.WARNING
          );
          return;
        }
        const createWorkspaceFields = {
          name: newWorkspaceName,
        } as CreateWorkspaceRequest;
        try {
          const newTeam = await createTeam({ name: newTeamName });
          createWorkspaceFields.teamId = newTeam.id;
        } catch (e) {
          snackbar.sendMessage(
            `Failed to create new Team. Try again later.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e, { extra: { newTeamName } });
          return;
        }
        try {
          const newWorkspace = await createWorkspace(createWorkspaceFields);
          createQuestFields.workspaceId = newWorkspace.id;
        } catch (e) {
          snackbar.sendMessage(
            `Failed to create new Workspace. Try again later.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e, { extra: { createWorkspaceFields } });
          // This ensures if they try again they will not create another new team
          setTeamIdForNewWorkspace(createWorkspaceFields.teamId);
          return;
        }
      }
    }

    return createQuest(createQuestFields)
      .then(async (newQuest) => {
        if (startTriggerType !== null) {
          const questPrototype = await fetchQuestPrototype(
            newQuest.currentQuestPrototype.id
          ).execute();

          const alreadyHasStartTriggerOfType =
            questPrototype.startTriggers.some(
              ({ type }) => type === startTriggerType
            );
          if (!alreadyHasStartTriggerOfType) {
            const startTriggerId = questPrototype.startTriggers[0]?.id;
            if (startTriggerId) {
              await updateQuestStartTrigger(questPrototype.id, startTriggerId, {
                enabled: true,
                type: startTriggerType,
              });
            }
          }
        }
        void updateTemplates();
        setQuestCreated(true);
        setTimeout(() => {
          setShowModal(false);
          navigation.navigate("Quest", {
            questId: newQuest.id,
            screen: "QuestEdit",
            params: {
              questPrototypeId: newQuest.currentQuestPrototype!.id,
            },
          });
        }, MODAL_CLOSE_DELAY);
      })
      .catch((e) => {
        snackbar.sendMessage(
          `Failed to create Quest. Please try again later.`,
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: {
            questName,
            workspaceId,
          },
        });
      });
  });

  // TODO: FINISH_TEAMS_FEATURE - Start of teams related hooks
  const [newWorkspaceName, setNewWorkspaceName] = useState("Main");
  const usersDisplayName = useAppSelector(
    (state) => selectLoggedInUser(state)?.displayName
  );
  const initialTeamId = useAppSelector((state) => {
    const workspaceId = state.ui.sideBar.activeWorkspaceId;
    if (!workspaceId) {
      return undefined;
    }
    return selectWorkspaceById(state, workspaceId)?.teamId || undefined;
  });
  const [teamIdForNewWorkspace, setTeamIdForNewWorkspace] = useState<
    string | undefined
  >(initialTeamId);
  const [newTeamName, setNewTeamName] = useState(
    usersDisplayName ? `${usersDisplayName}'s Team` : ""
  );
  // TODO: FINISH_TEAMS_FEATURE - End of teams related hooks

  const onStartTriggerTypeSelected = useCallback(
    (selection: StandardDropdownListItem<QuestStartTriggerType>) => {
      const startTriggerType = selection.value;
      Analytics.trackEvent("Select Quest Start Trigger Type For Create Quest", {
        sourceTemplateId,
        recommendedStartTriggerType,
        startTriggerType,
      });
      setStartTriggerType(startTriggerType);
      return Promise.resolve();
    },
    [recommendedStartTriggerType, sourceTemplateId]
  );

  return (
    <CreateQuestDialog>
      <View onStartShouldSetResponder={() => true}>
        <HeaderText title="Quest Name" />
        <StyledTextInput
          onChangeText={setQuestName}
          value={questName}
          placeholder={"Awesome Quest"}
          autoFocus={true}
          editable={!isLoading && !questCreated}
          onSubmitEditing={onCreateQuest}
        />
        {hasMultipleWorkspaces ? (
          <>
            <HeaderText title="Workspace" />
            <WorkspaceListOption
              setWorkspaceId={setWorkspaceId}
              workspaceId={workspaceId}
              allowCreate={TEAMS_ENABLED}
            />
          </>
        ) : null}
        {TEAMS_ENABLED && workspaceId === NEW_WORKSPACE_ID ? (
          <>
            <StyledTextInput
              onChangeText={setNewWorkspaceName}
              value={newWorkspaceName}
              placeholder={"Workspace Name"}
              autoFocus={true}
              editable={!isLoading && !questCreated}
            />
            <HeaderText title="Team" />
            <TeamList
              teamId={teamIdForNewWorkspace}
              setTeamId={setTeamIdForNewWorkspace}
              allowCreate={true}
            />
            {teamIdForNewWorkspace === NEW_TEAM_ID ? (
              <StyledTextInput
                onChangeText={setNewTeamName}
                value={newTeamName}
                placeholder={"Team Name"}
                autoFocus={true}
                editable={!isLoading && !questCreated}
              />
            ) : null}
          </>
        ) : null}
        <HeaderText title="Start Mode" />
        <DescriptionText>
          How do you plan to use this Quest? Choose a start trigger that matches
          your use case.
        </DescriptionText>
        <StartTriggerTypePicker
          onQuestTypeSelected={onStartTriggerTypeSelected}
          currentType={startTriggerType}
          recommendedStartTriggerType={recommendedStartTriggerType}
        />
        <StyledCreateQuestButton
          onPress={onCreateQuest}
          success={questCreated}
          loading={isLoading}
          title={createButtonText}
        />
      </View>
    </CreateQuestDialog>
  );
};

const DescriptionText = styled(Text)`
  ${Platform.OS === "web" ? "user-select: none" : ""}
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 24px;
`;

const CreateQuestDialog = styled.ScrollView`
  padding-horizontal: 12px;
  padding-vertical: 10px;
`;

const StyledCreateQuestButton = styled(Button)`
  align-self: center;
  margin-bottom: 24px;
  width: 100%;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 31px;
`;

const WorkspaceListOption = styled(WorkspaceList)`
  margin-bottom: 31px;
`;

interface StartTriggerDropdownOption
  extends StandardDropdownListItem<QuestStartTriggerType> {
  isRecommended: boolean;
}

type StartTriggerTypePickerProps = {
  currentType: QuestStartTriggerType | undefined;
  recommendedStartTriggerType?: QuestStartTriggerType;
  onQuestTypeSelected: (selection: StartTriggerDropdownOption) => Promise<void>;
};

export const StartTriggerTypePicker: React.FC<StartTriggerTypePickerProps> = (
  props
) => {
  const { recommendedStartTriggerType } = props;
  const options = useListViewModel(
    useMemo(
      () =>
        (
          [
            "BASIC",
            "SCHEDULE",
            "PUBLIC",
            "KIOSK",
          ] satisfies QuestStartTriggerType[]
        ).map(
          (type) =>
            ({
              value: type,
              label: QUEST_TYPES[type].name,
              icon: QUEST_TYPES[type].icon,
              isRecommended: recommendedStartTriggerType === type,
            } satisfies StartTriggerDropdownOption)
        ),
      [recommendedStartTriggerType]
    )
  );
  return (
    <StartTriggerTypePickerContainer>
      <InlineDropdown<StartTriggerDropdownOption>
        options={options}
        value={props.currentType}
        SelectionRenderer={StartTriggerDropdownSelectionRenderer}
        OptionRenderer={StartTriggerOptionRenderer}
        optionNoun={"Start Trigger Type"}
        optionPluralNoun={"Start Trigger Types"}
        loadingOptions={false}
        onSelect={props.onQuestTypeSelected}
        testID={"start-trigger-type-picker"}
      />
      {/*{orderedQuestTypes.map((questType) => {*/}
      {/*  return (*/}
      {/*    <QuestTypeListItem*/}
      {/*      key={questType}*/}
      {/*      questType={questType}*/}
      {/*      onQuestTypeSelected={props.onQuestTypeSelected}*/}
      {/*      isSelected={props.currentType === questType}*/}
      {/*      viewMode={"MINIMAL"}*/}
      {/*    />*/}
      {/*  );*/}
      {/*})}*/}
    </StartTriggerTypePickerContainer>
  );
};

const StartTriggerTypePickerContainer = styled.View`
  margin-bottom: 50px;
`;

interface StartTriggerOptionRendererProps<
  ITEM extends StartTriggerDropdownOption
> extends ListItemRendererProps<ITEM> {}

const StartTriggerOptionRenderer = <ITEM extends StartTriggerDropdownOption>({
  onSelect,
  item,
  parentTestID,
}: StartTriggerOptionRendererProps<ITEM>) => {
  const { hoverProps } = useHover();

  const value = item.value;
  const testID = useMemo(() => {
    return parentTestID ? `${parentTestID}-option-${value}` : undefined;
  }, [parentTestID, value]);

  const onQuestTypeSelected = useCallback(() => {
    onSelect();
    return Promise.resolve();
  }, [onSelect]);

  return (
    <DropdownOptionWrapper onPress={onSelect} testID={testID} {...hoverProps}>
      {/*<Icon inverted={true} icon={QUEST_TYPES[value].icon} offsetX={-1} />*/}
      {/*<DropdownOptionText>{QUEST_TYPES[value].name}</DropdownOptionText>*/}
      <StartTriggerTypeListItem
        questType={value}
        onQuestTypeSelected={onQuestTypeSelected}
        isSelected={false}
        viewMode={"DROPDOWN_OPTION"}
        isRecommended={item.isRecommended}
      />
    </DropdownOptionWrapper>
  );
};

const StartTriggerDropdownSelectionRenderer = <
  ITEM extends StartTriggerDropdownOption
>(
  props: DropdownSelectionRendererProps<ITEM>
) => {
  console.log("index:370 - props.value", props.value, "props.item", props.item);
  const { item } = props;
  if (!item) {
    return <StandardDropdownSelectionRenderer {...props} />;
  }

  const onQuestTypeSelected = useCallback(() => {
    return Promise.reject();
  }, []);

  return (
    <StartTriggerTypeListItem
      questType={item.value}
      onQuestTypeSelected={onQuestTypeSelected}
      isSelected={false}
      viewMode={"DROPDOWN_SELECTION"}
      isOpen={props.isOpen}
    />
  );
};

const DropdownOptionWrapper = styled.Pressable<{
  isHovered?: boolean;
}>`
  ${({ isHovered }) => `
    opacity: ${isHovered ? 0.8 : 1}
  `};
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default CreateQuest;
