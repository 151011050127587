import { useCallback, useEffect, useMemo, useRef } from "react";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import isEqual from "react-fast-compare";
import EventEmitter from "events";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";

export const useDataSelectorFactory = <DATA = unknown>(data: DATA) => {
  const dataObserver = useMemo(() => new DataObserver<DATA>(), []);
  dataObserver.update(data);

  const useDataSelector = useCallback(
    <OUTPUT = unknown>(selector: (data: DATA) => OUTPUT) => {
      const dataRef = useRef(dataObserver.data);
      const selectorRef = useRef(selector);
      selectorRef.current = selector;

      const selectValue = useCallback(
        () => selectorRef.current(dataRef.current),
        []
      );

      // must be `useState` so that updates trigger re-renders
      const [, setState, stateRef] = useStateWithRef(selectValue);
      const timeoutRef = useRef<NodeJS.Timeout | null>(null);

      const updateState = useCallback(() => {
        // No need to set another timeout if one is already scheduled to run.
        if (timeoutRef.current === null) {
          // Wait until the current render cycle is complete before updating the state and causing consumers of
          // `useValueWithChanges` to re-render.
          timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            const newState = selectValue();
            if (!isEqual(stateRef.current, newState)) {
              setState(newState);
            }
          }, 0);
        }
      }, [selectValue, setState, stateRef]);

      useEffect(() => {
        dataObserver.on("data", updateState);
        return () => {
          dataObserver.off("data", updateState);
        };
      }, [updateState]);

      // Re-select the value in case the selector function provided changed since the last render
      return useIsEqualMemo(selectValue());
    },
    [dataObserver]
  );

  return {
    useDataSelector,
  };
};

const NO_DATA = Symbol("no data");

class DataObserver<T = unknown> extends EventEmitter {
  private _data: T | typeof NO_DATA = NO_DATA;
  get data() {
    if (this._data === NO_DATA) {
      throw new Error("Data not set in DataObserver before being used!");
    }
    return this._data;
  }

  update(newData: T) {
    if (newData !== this._data) {
      this._data = newData;
      this.emit("data", newData);
    }
  }
}
