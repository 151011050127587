import React, { useContext, useRef, useState } from "react";
import styled from "styled-components/native";
import { mutate } from "swr";
import Button from "@app/components/questkit/button";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import Text from "@app/components/questkit/text";
import { Platform, View } from "react-native";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { updateQuests } from "@app/util/cacheMod";
import ListItem from "@app/components/questkit/listItem";
import DueDate from "../questRunOptionsDialog/dueDateOptions";
import { WorkspaceList } from "@app/components/questkit/WorkspaceList";
import {
  ReviewerPicker,
  ReviewerPickerController,
} from "@app/components/questkit/ReviewerPicker";
import { Analytics } from "@app/analytics";
import Switch from "@app/components/questkit/switch";
import {
  ChangeSet,
  extractUnsavedFields,
  isChangeSetTrackingValidValue,
  useChangeTracker,
} from "@app/quest/edit/useChangeTracker";
import { QuestStartTriggerType } from "@questmate/openapi-spec";
import {
  updateQuest,
  updateQuestArchiveStatus,
  updateQuestPrototype,
} from "@app/util/client/requests/quests";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { useAppSelector } from "@app/store";
import { selectLoggedInUser } from "@app/store/cache/users";
import { sentry } from "@app/util/sentry";
import { useQuestContext } from "@app/quest/QuestContext";
import { selectQuestById } from "@app/store/cache/quests";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { usePromise } from "@app/util/usePromise";
import QKScrollView from "@app/components/questkit/ScrollView";
import { createLink } from "@app/util/link.utils";
import { updateQuestReviewers } from "@app/util/client/requests/reviewers";
import { MODAL_CLOSE_DELAY } from "@app/components/modal";
import {
  selectQuestStartTriggerEditFields,
  StartTriggerEditFieldsForType,
} from "@app/store/cache/questStartTriggers";
import { updateQuestStartTrigger } from "@app/util/client/requests/questStartTriggers";
import Loader from "@app/components/animated/loader";
import isEqual from "react-fast-compare";

interface OptionsDialogProps {
  setShowOptionsModal: (showOptionsModal: boolean) => void;
  setShowDuplicateModal: (showDuplicateModal: boolean) => void;
}

const OptionsDialog: React.FC<OptionsDialogProps> = ({
  setShowOptionsModal,
  setShowDuplicateModal,
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const navigation = useAppNavigation();
  const {
    currentQuestPrototypeId: questPrototypeId,
    questId,
    advancedMode,
  } = useQuestContext();

  const userIsQuestmateAdmin = useAppSelector((state) =>
    selectLoggedInUser(state)?.email?.endsWith("@questmate.com")
  );
  const showTemplateVisibility = userIsQuestmateAdmin && advancedMode;

  const templateFromServer = useAppSelector((state) => {
    const quest = selectQuestById(state, questId);
    return quest
      ? {
          workspaceId: quest.workspaceId || null,
          templateVisibility: quest.templateVisibility,
          archived: quest.archived,
        }
      : undefined;
  }, isEqual);
  const {
    useValueWithChanges: useTemplateWithChanges,
    addChange: addTemplateChange,
    getChangeSet: getTemplateChangeSet,
  } = useChangeTracker(templateFromServer);
  const template = useTemplateWithChanges();

  const questPrototypeFromServer = useAppSelector((state) => {
    const qp = selectQuestPrototypeById(state, questPrototypeId);
    return qp
      ? {
          name: qp.name,
        }
      : undefined;
  }, isEqual);
  const {
    useValueWithChanges: useQuestPrototypeWithChanges,
    addChange,
    getChangeSet: getQuestPrototypeChangeSet,
  } = useChangeTracker(questPrototypeFromServer);
  const questPrototype = useQuestPrototypeWithChanges();

  const firstStartTriggerFromServer = useAppSelector((state) => {
    const qp = selectQuestPrototypeById(state, questPrototypeId);
    const firstStartTriggerId = qp?.startTriggerIds?.[0];
    let firstStartTrigger;
    if (firstStartTriggerId) {
      firstStartTrigger = selectQuestStartTriggerEditFields(
        state,
        questPrototypeId,
        firstStartTriggerId
      );

      if (!firstStartTrigger) {
        const error = new Error(
          "Unexpected: Start Trigger not found in cache."
        );
        sentry.captureException(error, {
          extra: {
            firstStartTriggerId,
            questPrototypeId,
            questPrototype: qp,
            startTriggersInCache: state.cache.questStartTriggers,
          },
        });
      }
    }
    return firstStartTrigger;
  }, isEqual);
  const {
    useValueWithChanges: useQuestStartTriggerWithChanges,
    addChange: addChangeToStartTrigger,
    getChangeSet: getQuestStartTriggerChangeSet,
  } = useChangeTracker(firstStartTriggerFromServer);
  const startTrigger = useQuestStartTriggerWithChanges();

  const [optionsSavedSuccessfully, setOptionsSavedSuccessfully] =
    useState(false);

  const { execute: saveReviewers } = usePromise(
    async (newReviewers: { id: string }[]) => {
      if (!startTrigger?.startConfiguration?.reviewers) {
        return;
      }
      return updateQuestReviewers(
        questPrototypeId,
        startTrigger.startConfiguration.reviewers,
        newReviewers
      )
        .then((result) => {
          if (result.errors.length > 0) {
            sentry.addBreadcrumb({
              message: "Errors saving reviewers",
              data: { errors: result.errors },
            });
            throw new Error("Failed to save reviewers");
          }
        })
        .catch((e) => {
          snackbarContext.sendMessage(
            `We unfortunately couldn't save your reviewers. Please try again later.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e);
          throw e;
        });
    }
  );
  const { execute: saveTemplate } = usePromise(
    async (templateChangeSet: ChangeSet<typeof template>) => {
      if (
        isChangeSetTrackingValidValue(templateChangeSet) &&
        templateChangeSet.hasUnsavedChanges
      ) {
        const unsavedFields = extractUnsavedFields(templateChangeSet, [
          ["workspaceId"],
          ["templateVisibility"],
        ]);

        templateChangeSet.markPending();
        return updateQuest(questId, unsavedFields)
          .then(() => {
            templateChangeSet.markSaved();
          })
          .catch((e) => {
            console.error(e);
            snackbarContext.sendMessage(
              `We unfortunately could not change the workspace. Please try again later.`,
              SnackbarSeverity.WARNING
            );
            sentry.captureException(e, {
              extra: { message: "Failed to update workspace" },
            });
            templateChangeSet.markUnsaved();
            throw e;
          });
      }
    }
  );

  const { execute: saveQuestPrototype } = usePromise(
    async (questPrototypeChangeSet: ChangeSet<typeof questPrototype>) => {
      if (
        isChangeSetTrackingValidValue(questPrototypeChangeSet) &&
        questPrototypeChangeSet.hasUnsavedChanges
      ) {
        const changedQuestPrototype = questPrototypeChangeSet.valueWithChanges;
        const unsavedFields = extractUnsavedFields(questPrototypeChangeSet, [
          ["name"],
        ]);

        questPrototypeChangeSet.markPending();
        return updateQuestPrototype(questPrototypeId, {
          ...unsavedFields,
        })
          .then(() => {
            questPrototypeChangeSet.markSaved();
            void mutate(["get", `/quests/${questPrototypeId}`]);
            void updateQuests();
          })
          .catch((e) => {
            questPrototypeChangeSet.markUnsaved();
            console.log(e);
            snackbarContext.sendMessage(
              `We unfortunately couldn't save. Please try again later.`,
              SnackbarSeverity.WARNING
            );
            sentry.captureException(e, {
              extra: {
                message: "Failed to save Template Options.",
                changedQuestPrototype,
              },
            });
            throw e;
          });
      }
    }
  );

  const { execute: saveQuestStartTrigger } = usePromise(
    async (questStartTriggerChangeSet: ChangeSet<typeof startTrigger>) => {
      if (
        startTrigger?.id &&
        isChangeSetTrackingValidValue(questStartTriggerChangeSet) &&
        questStartTriggerChangeSet.hasUnsavedChanges
      ) {
        const changedQuestStartTrigger =
          questStartTriggerChangeSet.valueWithChanges;
        const unsavedFields = extractUnsavedFields(questStartTriggerChangeSet, [
          ["startConfiguration", "dueAt"],
          ["startConfiguration", "dueAfterSeconds"],
          ["startConfiguration", "remindBeforeSeconds"],
          ["startConfiguration", "alertBeforeSeconds"],
          ["startConfiguration", "allowOverdueSubmissions"],
          ["startConfiguration", "slideMode"],
        ]);

        questStartTriggerChangeSet.markPending();
        return updateQuestStartTrigger(
          questPrototypeId,
          startTrigger.id,
          unsavedFields
        )
          .then(() => {
            questStartTriggerChangeSet.markSaved();
            void mutate(["get", `/quests/${questPrototypeId}`]);
          })
          .catch((e) => {
            questStartTriggerChangeSet.markUnsaved();
            console.log(e);
            snackbarContext.sendMessage(
              `We unfortunately couldn't save. Please try again later.`,
              SnackbarSeverity.WARNING
            );
            sentry.captureException(e, {
              extra: {
                message: "Failed to save Template Options.",
                changedQuestStartTrigger: changedQuestStartTrigger,
              },
            });
            throw e;
          });
      }
    }
  );

  const { execute: getReviewers } = usePromise(
    async (reviewerPicker: ReviewerPickerController) =>
      reviewerPicker.requiresReview && reviewerPicker.userPickerRef.current
        ? await reviewerPicker.userPickerRef.current.flush()
        : []
  );

  const { execute: onSave, isLoading: isSavingQuestAttributes } = usePromise(
    async () => {
      Analytics.trackEvent("Save Quest Options");
      const questPrototypeChangeSet = getQuestPrototypeChangeSet();
      const startTriggerChangeSet = getQuestStartTriggerChangeSet();
      const templateChangeSet = getTemplateChangeSet();
      if (!questPrototypeChangeSet.valueWithChanges?.name) {
        snackbarContext.sendMessage(
          `Please enter a name for the Template.`,
          SnackbarSeverity.WARNING
        );
        return;
      }

      let saveReviewersPromise: Promise<void> = Promise.resolve();
      if (reviewerPickerRef.current) {
        const reviewerPicker = reviewerPickerRef.current;
        const reviewers = await getReviewers(reviewerPicker);
        if (reviewerPicker.requiresReview && reviewers.length === 0) {
          snackbarContext.sendMessage(
            `Must set at least one reviewer when submissions require review.`,
            SnackbarSeverity.WARNING
          );
          return;
        }
        saveReviewersPromise = saveReviewers(reviewers);
      }

      await Promise.all([
        saveReviewersPromise,
        saveTemplate(templateChangeSet),
        saveQuestPrototype(questPrototypeChangeSet),
        saveQuestStartTrigger(startTriggerChangeSet),
      ])
        .then(() => {
          setOptionsSavedSuccessfully(true);
          setTimeout(() => setShowOptionsModal(false), MODAL_CLOSE_DELAY);
        })
        .catch(() => {
          // errors handled above
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  );

  const { execute: onChangeArchiveStatus, isLoading: isSavingArchiveStatus } =
    usePromise(async () => {
      const action: "archive" | "unarchive" = template?.archived
        ? "unarchive"
        : "archive";
      Analytics.trackEvent(
        `${template?.archived ? "Unarchive" : "Archive"} Quest`
      );
      return updateQuestArchiveStatus(questId, action)
        .then(async () => {
          setOptionsSavedSuccessfully(true);
          setTimeout(() => {
            setShowOptionsModal(false);
            if (action === "archive") {
              navigation.navigate("Home");
            }
          }, MODAL_CLOSE_DELAY);
        })
        .catch(() => {
          snackbarContext.sendMessage(
            `We unfortunately couldn't ${action} at this time. Please try again later.`,
            SnackbarSeverity.WARNING
          );
        });
    });
  const reviewerPickerRef = useRef<ReviewerPickerController>(null);

  const isSaving = isSavingArchiveStatus || isSavingQuestAttributes;

  return (
    <OptionsDialogWrapper>
      {!questPrototype || !startTrigger?.startConfiguration || !template ? (
        <Loader />
      ) : (
        <StyledScrollView keyboardShouldPersistTaps="always">
          <View onStartShouldSetResponder={() => true}>
            <OptionGroup>
              <HeaderText title="Quest Name" />
              <TextInput
                onChangeText={(name) => {
                  Analytics.trackEventDebounced(
                    questPrototypeId,
                    "Change Quest Name"
                  );
                  addChange((draft) => (draft.name = name));
                }}
                value={questPrototype.name}
                placeholder={"Awesome Quest"}
                editable={!isSaving && !optionsSavedSuccessfully}
              />
              {showTemplateVisibility ? (
                <TemplateVisibilityListItem
                  text="Template Visibility"
                  icon="group"
                  actionComponent={
                    <PillOptionToggler
                      onPress={() => {
                        addTemplateChange((draft) => {
                          switch (template.templateVisibility) {
                            case "PUBLIC":
                              draft.templateVisibility = "PUBLIC_UNLISTED";
                              break;
                            case "PUBLIC_UNLISTED":
                              draft.templateVisibility = "PRIVATE";
                              break;
                            case "PRIVATE":
                              draft.templateVisibility = "PUBLIC";
                              break;
                          }
                        });
                      }}
                      value={
                        template.templateVisibility === "PRIVATE"
                          ? "Private"
                          : template.templateVisibility === "PUBLIC_UNLISTED"
                          ? "Public (Unlisted)"
                          : template.templateVisibility === "PUBLIC"
                          ? "Public"
                          : "Unknown"
                      }
                    />
                  }
                />
              ) : null}
            </OptionGroup>

            <OptionGroup>
              <HeaderText title="Workspace" />
              <WorkspaceList
                setWorkspaceId={(workspaceId) =>
                  addTemplateChange((draft) => {
                    draft.workspaceId = workspaceId;
                    if (workspaceId === null) {
                      Analytics.trackEvent(
                        "Change Quest Workspace To Personal"
                      );
                    } else {
                      Analytics.trackEvent("Change Quest Workspace");
                    }
                  })
                }
                workspaceId={template.workspaceId}
              />
            </OptionGroup>
            <OptionGroup>
              <HeaderText title="Quest Options" />
              <ListItem
                text="Slide Mode"
                icon="slide-show"
                actionComponent={
                  <Switch
                    switchOn={startTrigger.startConfiguration!.slideMode}
                    onSwitch={() =>
                      addChangeToStartTrigger(
                        (draft) =>
                          (draft.startConfiguration!.slideMode =
                            !startTrigger.startConfiguration!.slideMode)
                      )
                    }
                  />
                }
              />
              {isStartTriggerOfType(startTrigger, ["SCHEDULE", "BASIC"]) ? (
                <DueDate
                  options={{
                    dueAt: startTrigger.startConfiguration!.dueAt,
                    dueAfterSeconds:
                      startTrigger.startConfiguration!.dueAfterSeconds,
                    remindBeforeSeconds:
                      startTrigger.startConfiguration!.remindBeforeSeconds,
                    alertBeforeSeconds:
                      startTrigger.startConfiguration!.alertBeforeSeconds,
                    allowOverdueSubmissions:
                      startTrigger.startConfiguration!.allowOverdueSubmissions,
                  }}
                  onChange={({
                    dueAt,
                    dueAfterSeconds,
                    remindBeforeSeconds,
                    alertBeforeSeconds,
                    allowOverdueSubmissions,
                  }) => {
                    addChangeToStartTrigger((draft) => {
                      draft.startConfiguration!.dueAt = dueAt;
                      draft.startConfiguration!.dueAfterSeconds =
                        dueAfterSeconds;
                      draft.startConfiguration!.remindBeforeSeconds =
                        dueAt || dueAfterSeconds ? remindBeforeSeconds : null;
                      draft.startConfiguration!.alertBeforeSeconds =
                        dueAt || dueAfterSeconds ? alertBeforeSeconds : null;
                      draft.startConfiguration!.allowOverdueSubmissions =
                        allowOverdueSubmissions;
                    });
                  }}
                />
              ) : null}
            </OptionGroup>
            {isStartTriggerOfType(startTrigger, ["KIOSK"]) ? null : (
              // Not well-supported for kiosk Quests
              <OptionGroup>
                <HeaderText title="Reviewers" />
                <ReviewerPicker
                  questPrototypeId={questPrototypeId}
                  startConfigurationId={startTrigger.startConfigurationId!}
                  readOnly={isSaving || optionsSavedSuccessfully}
                  ref={reviewerPickerRef}
                />
              </OptionGroup>
            )}
            <OptionGroup>
              <HeaderText title="Setup Automations" />
              <ListItem
                text="Add Submissions to Google Sheet"
                icon="export"
                actionIcon="chevron-right"
                onPress={createLink(
                  `https://zapier.com/webintent/create-zap/?template=885837&steps[0][params][templates]=${questId}&steps[0][meta][parammap][templates]=${questPrototype.name}&provider=questmate&entry-point-location=partner_embed`,
                  {
                    onPressHook: () =>
                      Analytics.trackEvent("Choose Automation", {
                        choice: "Add Submissions to Google Sheet",
                      }),
                  }
                )}
              />
              <ListItem
                text="Connect to other Apps"
                icon="upload"
                actionIcon="chevron-right"
                onPress={createLink(
                  `https://zapier.com/webintent/create-zap?template__0__action=quest_completed&template__0__selected_api=QuestMateCLIAPI%401.6.8&provider=questmate&entry-point-location=partner_embed`,
                  {
                    onPressHook: () =>
                      Analytics.trackEvent("Choose Automation", {
                        choice: "Connect to other Apps",
                      }),
                  }
                )}
              />
            </OptionGroup>
            <OptionGroup>
              <HeaderText title="Actions" />
              <ListItem
                text="Duplicate Quest"
                icon="duplicate"
                onPress={() => {
                  setShowOptionsModal(false);
                  setShowDuplicateModal(true);
                }}
              />
              <ListItem
                text={`${template?.archived ? "Unarchive" : "Archive"} Quest`}
                icon="trash"
                onPress={onChangeArchiveStatus}
              />
            </OptionGroup>
          </View>
        </StyledScrollView>
      )}
      <StyledOptionsDialogButton
        onPress={onSave}
        success={optionsSavedSuccessfully}
        loading={isSaving}
        title="Save"
      />
    </OptionsDialogWrapper>
  );
};

const Pill = styled.Pressable`
  height: 32px;
  width: 220px;
  border-radius: 16px;
  margin-right: 11px;

  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  ${() => Platform.OS === "web" && "user-select: none;"}
`;
type PillOptionTogglerProps = {
  value: string;
  onPress: () => void;
};
const PillOptionToggler: React.FC<PillOptionTogglerProps> = ({
  value,
  onPress,
}) => {
  return (
    <Pill onPress={onPress}>
      <Text $inverted={true}>{value}</Text>
    </Pill>
  );
};

const TemplateVisibilityListItem = styled(ListItem)`
  margin-top: 20px;
`;
const OptionGroup = styled.View`
  margin-bottom: 40px;
`;

const OptionsDialogWrapper = styled.View`
  padding-vertical: 10px;
  flex-shrink: 1;
`;

const StyledScrollView = styled(QKScrollView)`
  padding-horizontal: 20px;
`;
const StyledOptionsDialogButton = styled(Button)`
  width: 100%;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export default OptionsDialog;

const isStartTriggerOfType = <T extends QuestStartTriggerType>(
  startTrigger: StartTriggerEditFieldsForType<QuestStartTriggerType>,
  allowedQuestTypes: T[]
): startTrigger is StartTriggerEditFieldsForType<T> => {
  return (
    startTrigger?.type && allowedQuestTypes.includes(startTrigger.type as T)
  );
};
