import React, { useCallback, useMemo, useRef, useState } from "react";
import { Platform, TextInput as RNTextInput } from "react-native";
import styled from "styled-components/native";
import { useRefSynchronizer } from "@app/util/useRefSynchronizer";
import { useItems } from "@app/quest/edit/items";
import { Text } from "@app/components/questkit/text";
import TextInput from "@app/components/questkit/textInput";
import {
  identifyKnownExpression,
  labelForExpressionParts,
} from "@app/quest/edit/ExpressionParser";
import { NativeSyntheticEvent } from "react-native/Libraries/Types/CoreEventTypes";
import {
  TextInputKeyPressEventData,
  TextInputSelectionChangeEventData,
} from "react-native/Libraries/Components/TextInput/TextInput";
import {
  type StandardDropdownListItem,
  StandardInlineDropdown,
} from "@app/components/questkit/dropdown/StandardInlineDropdown";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { useSyncedState } from "@app/components/item/components/custom/edit/useSyncedState";
import BasePressable from "@app/components/questkit/BasePressable";
import PressableOpacity from "@app/components/questkit/PressableOpacity";
import Icon, { NO_ICON } from "@app/components/icon";
import QKIcon from "@app/components/questkit/icon";
import { colors } from "@app/themes/Colors";
import {
  QuestDataContextKey,
  QuestRunExpressionItemsRootKey,
} from "@questmate/questscript";
import { type PlaceholderTextProviderMap } from "@app/components/questkit/dropdown/inlineDropdown";

export interface ExpressionInputProps {
  value: string;
  onChange?: (expressionString: string) => void;
}

type TextToken = {
  type: "text";
  startIndex: number;
  endIndex: number; // exclusive
  text: string;
};
type ExpressionToken = {
  type: "expression";
  startIndex: number;
  endIndex: number; // exclusive
  expression: string;
};

type CustomTextToken = TextToken | ExpressionToken;

function getSelectedTokenIndex(
  tokens: (TextToken | ExpressionToken)[],
  selection: { end: number; start: number },
  endIndexInclusive: boolean
) {
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (
      // Selecting entire token
      (selection.start === token.startIndex &&
        selection.end === token.endIndex) ||
      // OR Selection is within the token
      (selection.start > token.startIndex &&
        (endIndexInclusive
          ? selection.end <= token.endIndex
          : selection.end < token.endIndex))
    ) {
      return i;
    }
  }
  return -1;
}

type ItemExpression =
  `{{${QuestRunExpressionItemsRootKey}[${string}]${string}}}`;
type ContextDataExpression = `{{${QuestDataContextKey}${string}}}`;

export const ExpressionInput = React.forwardRef<
  RNTextInput,
  ExpressionInputProps
>(({ value: _value, onChange }, parentRef) => {
  const { items } = useItems();

  const [isFocused] = useState(false);

  const textInputRef = useRef<RNTextInput>();
  const ref = useRefSynchronizer(parentRef, textInputRef);

  const [value, setValue, valueRef] = useSyncedState(_value, {
    onLocalChange: onChange,
  });

  const expressionTokens = useMemo(() => convertStringToTokens(value), [value]);
  const expressionTokensRef = useRef(expressionTokens);
  expressionTokensRef.current = expressionTokens;

  function setTextInputSelection(newSelection: { end: number; start: number }) {
    const textInput = textInputRef.current;
    if (!textInput) {
      return;
    }

    if (Platform.OS === "web") {
      (
        textInput as unknown as {
          setSelectionRange: (start: number, end: number) => void;
        }
      ).setSelectionRange(newSelection.start, newSelection.end);
    } else {
      textInput.setNativeProps({
        selection: newSelection,
      });
    }
  }

  const ignoreAnySelectionsRef = useRef(false);
  const selectionRef = useRef({ start: -1, end: -1 });
  const [selectedTokenIndex, setSelectedTokenIndex, selectedTokenIndexRef] =
    useStateWithRef(-1);
  const lastKeyPressRef = useRef<TextInputKeyPressEventData["key"] | null>(
    null
  );
  const onSelectionChange = useCallback(
    (e: NativeSyntheticEvent<TextInputSelectionChangeEventData>) => {
      if (ignoreAnySelectionsRef.current) {
        // SAFARI-FIX-1: Safari does not maintain selection when blur then re-focus, causing an additional
        // `onSelectionChange` event that should be ignored to avoid incorrect flicker of selection.
        return;
      }
      const prevSelectedTokenIndex = selectedTokenIndexRef.current;

      const newSelection = e?.nativeEvent?.selection ?? {
        start: -1,
        end: -1,
      };
      selectionRef.current = newSelection;

      const selectedCharactersCount = Math.abs(
        newSelection.end - newSelection.start
      );
      const newSelectedTokenIndex = getSelectedTokenIndex(
        expressionTokensRef.current,
        newSelection,
        lastKeyPressRef.current === "Backspace" || selectedCharactersCount > 0
      );
      setSelectedTokenIndex(newSelectedTokenIndex);

      const newSelectedToken =
        expressionTokensRef.current[newSelectedTokenIndex];

      const shouldSelectWholeExpressionToken =
        newSelectedTokenIndex >= 0 &&
        prevSelectedTokenIndex !== newSelectedTokenIndex &&
        newSelectedToken.type === "expression" &&
        // User did not manually select more than one character
        selectedCharactersCount < 2;
      if (shouldSelectWholeExpressionToken) {
        setTextInputSelection({
          start: newSelectedToken.startIndex,
          end: newSelectedToken.endIndex,
        });
      }
    },
    [selectedTokenIndexRef, setSelectedTokenIndex]
  );
  const options: StandardDropdownListItem<string>[] = useMemo(() => {
    const itemOptions = items.map(
      (item): StandardDropdownListItem<ItemExpression> => {
        // Just the unique id of the item, not including the `prototypeId` which changes on clone or Quest start.
        const stableItemId = item.prototype.id.split(":")[1];
        return {
          icon: "item" as const,
          value: `{{items["${stableItemId}"].stringValue}}`,
          label: item.prototype.name,
        };
      }
    );

    const contextDataOptions = [
      {
        icon: "clock",
        value: "{{completedAt.stringValue}}",
        label: "Quest Completion Time",
      },
      {
        icon: "text",
        value: "{{runName.stringValue}}",
        label: "Quest Run Name",
      },
      {
        icon: "person",
        value: "{{submittedBy.stringValue}}",
        label: "Quest Submitter Name",
      },
      {
        icon: "person",
        value: "{{submittedBy.rawValue}}",
        label: "Quest Submitter User ID",
      },
    ] satisfies StandardDropdownListItem<ContextDataExpression>[];

    return [...contextDataOptions, ...itemOptions];
  }, [items]);

  const onSelectQuestData = useCallback(
    (selection: StandardDropdownListItem<string>) => {
      const expression = selection.value;
      if (expression) {
        const selectionStart = Math.max(selectionRef.current.start, 0);
        const selectionEnd = Math.max(selectionRef.current.end, 0);
        setValue(
          valueRef.current.slice(0, selectionStart) +
            expression +
            valueRef.current.slice(selectionEnd)
        );
        // SAFARI-FIX-1: Safari does not maintain selection when blur then re-focus, causing an additional
        // `onSelectionChange` event that should be ignored to avoid incorrect flicker of selection.
        ignoreAnySelectionsRef.current = true;
        textInputRef.current?.focus();
        setTimeout(
          () => {
            ignoreAnySelectionsRef.current = false;
            setTextInputSelection({
              start: selectionStart,
              end: selectionStart + expression.length,
            });
          },
          // SAFARI-FIX-2: Wait till new value is rendered. Zero works on FF & Chrome, Safari is slow to render new updates.
          // Tried changing to event based, but Safari still hasn't updated the value after we have rendered the
          // input with the new value.
          50
        );
      }
    },
    [setValue, valueRef]
  );

  const [viewMode, setViewMode] = useState<"COLLAPSED" | "EXPANDED">(
    "COLLAPSED"
  );

  const placeholderTextOverridesMap = useMemo(
    (): Partial<PlaceholderTextProviderMap> => ({
      OPTIONS_AVAILABLE: ({ optionNoun }) =>
        `Insert ${optionNoun.toLowerCase()}`,
    }),
    []
  );

  const rightIcon = useCallback(() => {
    if (viewMode === "COLLAPSED") {
      return <Icon icon={NO_ICON} />;
    }
    return (
      <PressableOpacity onPress={() => setViewMode("COLLAPSED")}>
        <QKIcon name={"checkmark"} />
      </PressableOpacity>
    );
  }, [viewMode]);
  const onFocus = useCallback(() => {
    setViewMode("EXPANDED");
  }, []);
  const onBlur = useCallback(() => {
    lastKeyPressRef.current = null;
    setSelectedTokenIndex(-1);
  }, [setSelectedTokenIndex]);
  const onKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      lastKeyPressRef.current = e.nativeEvent.key;
    },
    []
  );
  return (
    <StyledTextInputContainer isFocused={isFocused}>
      <StyledTextWrapper>
        <SectionHeaderText>Expression</SectionHeaderText>
        <TextTokenInput
          value={value}
          ref={ref}
          multiline={viewMode === "EXPANDED"}
          placeholder={"Type here or insert a data expression"}
          blurOnSubmit={false}
          onChangeText={setValue}
          onSelectionChange={onSelectionChange}
          rightIcon={rightIcon}
          leftIcon={"function"}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
        {viewMode === "EXPANDED" ? (
          <QuestDataPickerInlineDropdown
            value={undefined as string | undefined}
            options={options}
            optionNoun={"Quest Data"}
            optionPluralNoun={"Quest Data"}
            loadingOptions={false}
            onSelect={onSelectQuestData}
            placeholderTextOverridesMap={placeholderTextOverridesMap}
          />
        ) : null}
        <SectionHeaderText>Preview</SectionHeaderText>
        <PreviewText>
          {expressionTokens.map((token, index) =>
            token.type === "expression" ? (
              <ExpressionPill
                key={`data-${index}`}
                expression={token.expression}
                active={selectedTokenIndex === index}
                onPress={() => {
                  const focusAndSelect = () => {
                    textInputRef.current?.focus();
                    setTextInputSelection({
                      start: token.startIndex,
                      end: token.endIndex,
                    });
                  };

                  if (viewMode === "EXPANDED") {
                    focusAndSelect();
                  } else {
                    setViewMode("EXPANDED");
                    setTimeout(
                      focusAndSelect,
                      // Wait for focus and expansion of field
                      50
                    );
                  }
                }}
              />
            ) : (
              <TextPart key={`text-${index}`}>{token.text}</TextPart>
            )
          )}
        </PreviewText>
      </StyledTextWrapper>
    </StyledTextInputContainer>
  );
});
ExpressionInput.displayName = "ExpressionInput";

type DataPillProps = {
  expression: string;
  active: boolean;
  onPress: () => void;
};

export const ExpressionPill: React.FC<DataPillProps> = ({
  expression,
  active,
  onPress,
}) => {
  const { items } = useItems();

  const knownExpression = useMemo(
    () => identifyKnownExpression(expression),
    [expression]
  );

  const { label, dataType } = useMemo(() => {
    const expressionParts = knownExpression?.parts;
    if (
      !knownExpression ||
      !Array.isArray(expressionParts) ||
      !expressionParts.length
    ) {
      return { label: "Unknown", dataType: "unknown" };
    }

    return labelForExpressionParts({ items }, expressionParts);
  }, [knownExpression, items]);

  return (
    <DataPillContainer_NATIVE_MARGIN_GROW_FIX>
      <DataPillPressable active={active} onPress={onPress}>
        <Text>{label}</Text>
        {dataType === "string" ? null : (
          <Icon
            container={{ width: 20, height: 22 }}
            size={16}
            icon={dataType === "value" ? "code-object" : "function"}
          />
        )}
      </DataPillPressable>
    </DataPillContainer_NATIVE_MARGIN_GROW_FIX>
  );
};

const QuestDataPickerInlineDropdown = styled(StandardInlineDropdown)`
  width: 100%;
`;

const SectionHeaderText = styled(Text).attrs({ size: "mediumBold" })`
  color: ${colors.neutral700};
  margin-top: 8px;
`;

const TextPart = styled(Text)`
  ${Platform.OS === "ios" ? `line-height: 32px;` : ""}
`;

const PreviewText = styled(Text)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TextTokenInput = styled(TextInput)`
  width: 100%;
  ${Platform.OS === "web" ? "outline: none" : ""}
  margin-vertical: 10px;
`;

/**
 * This is a fix for the margin issue on Native with the `DataPillPressable`.
 * Adding `margin-bottom` or `margin-horizontal` to this view or the `DataPillPressable` will cause the pill
 * to begin growing to infinite size, freeze the app and print the following error:
 *    "Excessive number of pending callbacks: 501."
 * The error log shows that there are many "measureInWindow" callbacks happening.
 *
 * This appears like it may be a bug in react-native or styled-components.
 * Similar bugs have come up before but have been "fixed"...
 * Similar bug: https://stackoverflow.com/a/75189431/2703729
 */
const DataPillContainer_NATIVE_MARGIN_GROW_FIX = styled.View`
  padding-horizontal: 1px;
`;

const DataPillPressable = styled(BasePressable)<{ active: boolean }>`
  flex-direction: row;
  background-color: ${colors.primary50};
  border: 1px solid
    ${({ theme, active }) =>
      theme.textInput[active ? "focused" : "normal"].border};
  border-radius: 5px;
  padding-horizontal: 4px;
  padding-vertical: 1px;
`;

const StyledTextWrapper = styled.View`
  padding: ${Platform.OS === "ios" ? 1 : 7}px 20px 7px 20px;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 10;
`;

const StyledTextInputContainer = styled.View<{
  isFocused: boolean;
}>`
  background-color: ${({ theme, isFocused }) =>
    isFocused
      ? theme.textInput.focused.background
      : theme.textInput.normal.background};
  min-height: 40px;
  border-radius: 20px;
  overflow: hidden;
  border-width: 1px;
  border-color: ${({ theme, isFocused }) =>
    isFocused ? theme.textInput.focused.border : theme.textInput.normal.border};
  flex-direction: row;
  align-items: center;
  z-index: 10;
`;

const convertStringToTokens = (value: string) => {
  const tokens: CustomTextToken[] = [];
  let currentValue = value;
  let currentIndex = 0;
  while (currentValue) {
    let indexInCurrentValue = 0;
    const result = /\{\{(.*?)}}/.exec(currentValue);
    if (result) {
      if (result.index > 0) {
        tokens.push({
          type: "text",
          startIndex: currentIndex,
          endIndex: currentIndex + result.index,
          text: currentValue.substring(indexInCurrentValue, result.index),
        });
        indexInCurrentValue += result.index;
      }
      tokens.push({
        type: "expression",
        startIndex: currentIndex + indexInCurrentValue,
        endIndex: currentIndex + indexInCurrentValue + result[0].length,
        expression: result[1],
      });
      indexInCurrentValue += result[0].length;
    } else {
      tokens.push({
        type: "text",
        startIndex: currentIndex + indexInCurrentValue,
        endIndex: currentIndex + currentValue.length,
        text: currentValue.substring(indexInCurrentValue),
      });
      indexInCurrentValue = currentValue.length;
    }
    currentValue = currentValue.substring(indexInCurrentValue);
    currentIndex += indexInCurrentValue;
  }
  return tokens;
};
