import * as React from "react";
import { useMemo, useState } from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import Icon from "@app/components/icon";
import { QuestStartTriggerType } from "@questmate/openapi-spec";
import QKModal, { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { Platform, Pressable, ScrollView, View } from "react-native";
import { QuestSectionHeader } from "@app/components/screen/quest/questSectionHeader";
import Button from "@app/components/questkit/button";
import { usePromise } from "@app/util/usePromise";
import { useModal } from "@app/components/modal/ModalManager";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { Analytics } from "@app/analytics";
import { useHover } from "@app/util/useHover";

type StartTriggerTypeListItemProps = {
  questType: QuestStartTriggerType;
  onQuestTypeSelected?: (questType: QuestStartTriggerType) => Promise<void>;
  isSelected: boolean;
  viewMode: "DROPDOWN_SELECTION" | "DROPDOWN_OPTION";
  isOpen?: boolean; // Only when 'DROPDOWN_SELECTION'
  isRecommended?: boolean; // Only when 'DROPDOWN_OPTION'
};
export const StartTriggerTypeListItem: React.FC<
  StartTriggerTypeListItemProps
> = ({
  questType,
  onQuestTypeSelected,
  isSelected,
  viewMode,
  isOpen = false,
  isRecommended = false,
}) => {
  const { icon, name, shortDescription, useCases } = QUEST_TYPES[questType];
  const { hoverProps } = useHover();

  const { openModal, closeModal, addListener } = useModal(
    ({ showModal, setShowModal }) => {
      if (!onQuestTypeSelected) {
        throw new Error(
          "Unexpected: Missing onQuestTypeSelected. Required to open Start Trigger Modal."
        );
      }
      return (
        <QKModal
          title={`Select Quest Mode`}
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <QuestTypeModalView
            questType={questType}
            closeModal={closeModal}
            onQuestTypeSelected={onQuestTypeSelected}
          />
        </QKModal>
      );
    }
  );

  useEffectOnce(() =>
    addListener((event) => {
      if ("showModal" in event.updatedFields) {
        const modalOpened = event.updatedFields.showModal;
        if (modalOpened) {
          Analytics.trackEvent("Open Quest Type Modal", { questType });
        } else {
          Analytics.trackEvent("Close Quest Type Modal", { questType });
        }
      }
    })
  );

  const onPress = useMemo(() => {
    if (viewMode === "DROPDOWN_OPTION") {
      return () => void onQuestTypeSelected!(questType);
    } else if (viewMode === "DROPDOWN_SELECTION") {
      return undefined;
    } else {
      return () => openModal();
    }
  }, [onQuestTypeSelected, openModal, questType, viewMode]);

  const inverted =
    viewMode === "DROPDOWN_OPTION" ||
    (viewMode === "DROPDOWN_SELECTION" && isOpen);
  return (
    <>
      <StartTriggerTypeCard
        onPress={onPress}
        disabled={!onPress}
        testID={`quest-type-${questType}`}
        isSelected={isSelected}
        viewMode={viewMode}
        inverted={inverted}
        {...hoverProps}
      >
        {/*<QuestTypeIconContainer viewMode={viewMode} inverted={inverted}>*/}
        {/*  <StyledIcon*/}
        {/*    icon={icon}*/}
        {/*    inverted={inverted}*/}
        {/*    size={viewMode === "STANDALONE" ? 60 : 32}*/}
        {/*  />*/}
        {/*</QuestTypeIconContainer>*/}
        <QuestTypeCardContent viewMode={viewMode}>
          <QuestTypeTitleContainer>
            <StartTriggerTypeIcon
              icon={icon}
              inverted={inverted}
              size={32}
              container={"COLLAPSED"}
            />
            <QuestTypeName inverted={inverted}>{name}</QuestTypeName>
          </QuestTypeTitleContainer>
          <QuestTypeDescription inverted={inverted}>
            {shortDescription}
          </QuestTypeDescription>
          {viewMode !== "DROPDOWN_SELECTION" ? (
            <QuestTypeCardUseCasesSection>
              {useCases.map((useCase) => {
                return (
                  <QuestTypeUseCasePill
                    key={`${name}-${useCase}`}
                    inverted={inverted}
                  >
                    <QuestTypeUseCasePillText inverted={inverted}>
                      {useCase}
                    </QuestTypeUseCasePillText>
                  </QuestTypeUseCasePill>
                );
              })}
            </QuestTypeCardUseCasesSection>
          ) : null}
        </QuestTypeCardContent>
        {/*{viewMode === "STANDALONE" ? (*/}
        {/*  <ExpandIconAligner>*/}
        {/*    <Icon icon="expand" size={32} />*/}
        {/*  </ExpandIconAligner>*/}
        {/*) : null}*/}
        {viewMode === "DROPDOWN_SELECTION" ? (
          <DropdownOpenIndicatorContainer>
            <DropdownOpenIndicator
              isOpen={isOpen}
              icon={isOpen ? "chevron-up" : "chevron-down"}
              size={32}
            />
          </DropdownOpenIndicatorContainer>
        ) : (
          <ExpandIconAligner>
            {viewMode === "DROPDOWN_OPTION" ? (
              <Pressable onPress={openModal}>
                <StyledIcon icon="info" size={32} inverted={inverted} />
              </Pressable>
            ) : null}
          </ExpandIconAligner>
        )}
        {isRecommended ? (
          <StarIconAligner>
            <StyledIcon icon="star-filled-in" size={32} inverted={inverted} />
            {/*<MaskedView*/}
            {/*  style={{ flex: 1, flexDirection: 'row', height: '100%' }}*/}
            {/*  maskElement={*/}
            {/*<StarIcon icon="star-filled-in" size={32} />*/}
            {/*  }*/}
            {/*>*/}
            {/* <AnimatedGradient speed={100}/>*/}
            {/*</MaskedView>*/}
          </StarIconAligner>
        ) : null}
      </StartTriggerTypeCard>
    </>
  );
};

const DropdownOpenIndicator = styled(Icon)<{
  isOpen: boolean;
}>`
  color: ${({ theme, isOpen }) => (isOpen ? theme.background : theme.primary)};
`;

const DropdownOpenIndicatorContainer = styled.View`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const ExpandIconAligner = styled.View`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const StarIconAligner = styled.View`
  position: absolute;
  bottom: 15px;
  right: 10px;
`;
// width: 40px;
// height: 40px;

// const StarIcon = styled(Icon)`
//   color: transparent;
// `;
const QuestTypeTitleContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;
const QuestTypeName = styled(Text).attrs({ size: "mediumLargeBold" })<{
  inverted: boolean;
}>`
  margin-bottom: 10px;
  ${Platform.OS === "web" ? "user-select: none" : ""}
  color: ${({ inverted, theme }) =>
    inverted ? theme.background : theme.primary};
  padding-left: 8px;
`;

const QuestTypeDescription = styled(Text).attrs({
  size: "small",
})<{ inverted: boolean }>`
  ${Platform.OS === "web" ? "user-select: none" : ""}
  color: ${({ inverted, theme }) =>
    inverted ? theme.background : theme.primary};
  padding-left: 40px;
`;

const StyledIcon = styled(Icon)<{ inverted: boolean }>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.background : theme.primary};
`;
const StartTriggerTypeIcon = styled(Icon)<{ inverted: boolean }>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.background : theme.primary};
  padding-bottom: 8px;
  //padding-left: 8px;
`;
const QuestTypeCardUseCasesSection = styled.View`
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  height: 30px;
  padding-left: 40px;
`;

const QuestTypeModalUseCasesSection = styled.View`
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const QuestTypeUseCasePillText = styled(Text).attrs({
  size: "small",
  numberOfLines: 1,
})<{ inverted: boolean }>`
  color: ${({ theme, inverted }) =>
    inverted ? theme.primary : theme.background};
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;
const QuestTypeUseCasePill = styled.View<{ inverted: boolean }>`
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${({ theme, inverted }) =>
    inverted ? theme.background : theme.primary};
  margin-right: 10px;
  margin-bottom: 10px;
  height: 30px;
`;

const QuestTypeCardContent = styled.View<{
  viewMode: StartTriggerTypeListItemProps["viewMode"];
}>`
  flex: 1;
  justify-content: center;
  padding-left: 16px;
  padding-right: 40px;
  ${({ viewMode }) =>
    viewMode === "DROPDOWN_SELECTION"
      ? "padding-vertical: 10px;"
      : "padding-top: 20px;"}
`;
// const QuestTypeIconContainer = styled.View<{
//   inverted: boolean;
//   viewMode: StartTriggerTypeListItemProps["viewMode"];
// }>`
//   width: 60px;
//   align-items: center;
//   justify-content: center;
//   margin-right: 20px;
//   border-style: solid;
//   border-color: ${({ theme, inverted }) =>
//     inverted ? theme.background : theme.primary};
//   border-right-width: 1px;
// `;
const StartTriggerTypeCard = styled(Pressable)<{
  isHovered: boolean;
  isSelected: boolean;
  viewMode: StartTriggerTypeListItemProps["viewMode"];
  inverted: boolean;
}>`
  flex-direction: row;
  background-color: ${({ theme, isHovered, viewMode, inverted }) =>
    isHovered && viewMode !== "DROPDOWN_SELECTION"
      ? inverted
        ? theme.primary
        : theme.cardInCard
      : inverted
      ? theme.primary
      : viewMode === "DROPDOWN_SELECTION"
      ? theme.background
      : theme.card.background};
  overflow: hidden;
  max-width: 540px;
  width: 100%;
  min-height: ${({ viewMode }) => (viewMode === "DROPDOWN_OPTION" ? 80 : 60)}px;
  ${({ viewMode }) =>
    viewMode === "DROPDOWN_SELECTION"
      ? ""
      : "transition: background-color 0.15s ease-in-out;"}

  border: 1px solid
    ${({ isHovered, isSelected, theme, viewMode, inverted }) =>
    (isHovered || isSelected) && viewMode !== "DROPDOWN_SELECTION"
      ? inverted
        ? theme.cardInCard
        : theme.textInput.focused.border
      : theme.textInput.normal.border};
  ${({ viewMode }) =>
    viewMode === "DROPDOWN_OPTION"
      ? "border-left-width: 0; border-right-width: 0; border-bottom-width: 0;"
      : viewMode === "DROPDOWN_SELECTION"
      ? "border-width: 0;"
      : ""}
`;

type QuestTypeModalViewProps = {
  questType: QuestStartTriggerType;
  closeModal: () => void;
  onQuestTypeSelected: (questType: QuestStartTriggerType) => Promise<void>;
};
export const QuestTypeModalView: React.FC<QuestTypeModalViewProps> = ({
  questType,
  closeModal,
  onQuestTypeSelected,
}) => {
  const [typeChangedSuccessfully, setTypeChangedSuccessfully] = useState(false);
  const { execute: onSelectQuestType, isLoading } = usePromise(async () => {
    setTypeChangedSuccessfully(false);
    return onQuestTypeSelected(questType).then(() => {
      setTypeChangedSuccessfully(true);
      setTimeout(() => {
        closeModal();
      }, MODAL_CLOSE_DELAY);
    });
  });

  const { icon, useCases, features, name, longDescription } =
    QUEST_TYPES[questType];

  return (
    <QuestTypeModalViewContainer>
      <ScrollView>
        <View onStartShouldSetResponder={() => true}>
          <QuestTypeIconModalContainer>
            <Icon icon={icon} size={80} />
          </QuestTypeIconModalContainer>
          <QuestTypeNameInModal>{name}</QuestTypeNameInModal>
          <QuestTypeDescriptionInModal>
            {longDescription}
          </QuestTypeDescriptionInModal>
          <FeaturesSectionHeader text="Features" />
          <FeaturesListContainer>
            {features.map((feature, index) => {
              return (
                <FeatureListItemRow key={`${name}-feature-${index}`}>
                  <FeatureListBulletPointAligner>
                    <FeatureListBulletPointIcon icon="star-filled-in" />
                  </FeatureListBulletPointAligner>

                  <FeatureListText>{feature}</FeatureListText>
                </FeatureListItemRow>
              );
            })}
          </FeaturesListContainer>
          <FeaturesSectionHeader text="Common Use Cases" />
          <QuestTypeModalUseCasesSection>
            {useCases.map((useCase) => {
              return (
                <QuestTypeUseCasePill
                  key={`${name}-${useCase}`}
                  inverted={false}
                >
                  <QuestTypeUseCasePillText inverted={false}>
                    {useCase}
                  </QuestTypeUseCasePillText>
                </QuestTypeUseCasePill>
              );
            })}
          </QuestTypeModalUseCasesSection>
        </View>
      </ScrollView>
      <SelectQuestTypeButton
        title="Choose this Start Mode"
        testID="choose-quest-type"
        onPress={onSelectQuestType}
        loading={isLoading}
        disabled={isLoading}
        success={typeChangedSuccessfully}
      />
      <CloseModalLink onPress={closeModal}>
        <CloseModalLinkText>Close</CloseModalLinkText>
      </CloseModalLink>
    </QuestTypeModalViewContainer>
  );
};

const SelectQuestTypeButton = styled(Button)`
  margin-top: 20px;
`;
const CloseModalLink = styled(Pressable)`
  margin-top: 20px;
  align-items: center;
`;
const FeatureListBulletPointIcon = styled(Icon)``;
const CloseModalLinkText = styled(Text).attrs({ $underlined: true })`
  ${Platform.OS === "web" ? "user-select: none" : ""}
`;
const FeaturesListContainer = styled.View`
  margin-bottom: 30px;
`;

const FeatureListItemRow = styled.View`
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const FeatureListText = styled(Text).attrs({ size: "small" })``;
const FeatureListBulletPointAligner = styled.View`
  justify-content: center;
  align-items: center;
  height: 19px;
  width: 19px;
  margin-left: -1px;
  margin-right: 5px;
`;

const FeaturesSectionHeader = styled(QuestSectionHeader)`
  margin-horizontal: 0;
`;

const QuestTypeNameInModal = styled(Text).attrs({ size: "large" })`
  margin-bottom: 20px;
  text-align: center;
`;

const QuestTypeDescriptionInModal = styled(Text).attrs({
  size: "medium",
})`
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
`;

const QuestTypeIconModalContainer = styled.View`
  align-items: center;
`;
const QuestTypeModalViewContainer = styled.View`
  flex-shrink: 1;
  padding: 0 20px 20px 20px;
`;
