import {
  QuestInstanceDetail,
  QuestPrototypeDetail,
  TemplateDetails,
} from "@questmate/openapi-spec";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import { userLogout } from "@app/store/auth";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { UnionToIntersection } from "@questmate/common";
import { questInstanceLoaded } from "@app/store/cache/questInstances";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import { questLoaded } from "@app/store/cache/quests";

export type CachedItemPrototype<T extends APIItemPrototype> = Omit<
  T,
  "subquest" | "icon" | "personalData"
> & {
  subquestId?: string;
};

type ItemPrototype = CachedItemPrototype<UnionToIntersection<APIItemPrototype>>;

const itemPrototypeAdapter = createEntityAdapter<ItemPrototype>({});

export const {
  selectById: selectItemPrototypeById,
  selectEntities: selectAllItemPrototypesById,
} = itemPrototypeAdapter.getSelectors<AppState>(
  (state) => state.cache.itemPrototypes
);

const slice = createSlice({
  name: "cache/itemPrototypes",
  initialState: itemPrototypeAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(questPrototypeLoaded, (state, action) =>
        itemPrototypeAdapter.setMany(
          state,
          action.payload.items.map(mapItemPrototype)
        )
      )
      .addCase(questInstanceLoaded, (state, action) => {
        const items = action.payload.prototype.items;
        if (items) {
          itemPrototypeAdapter.upsertMany(state, items.map(mapItemPrototype));
        }
      })
      .addCase(questLoaded, (state, action) => {
        const qp = action.payload.currentQuestPrototype;
        if (qp && "items" in qp) {
          return itemPrototypeAdapter.upsertMany(
            state,
            qp.items.map(mapItemPrototype)
          );
        }
      })
      .addCase(userLogout, (state) => itemPrototypeAdapter.removeAll(state));
  },
});

const reducer = slice.reducer;
export default reducer;

type APIItemPrototype =
  | QuestPrototypeDetail["items"][number]
  | QuestInstanceDetail["prototype"]["items"][number]
  | TemplateDetails["currentQuestPrototype"]["items"][number];

const mapItemPrototype = createDataMapper<APIItemPrototype, ItemPrototype>()(
  [
    "id",
    "type",
    "name",
    "defaults",
    "position",
    "infos",
    "referenceSlug",
    "required",
    "customScript",
    "isCompletionAction",
  ],
  {
    subquest: (subquest) => (subquest?.id ? { subquestId: subquest.id! } : {}),
  }
);
