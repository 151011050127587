import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import styled from "styled-components/native";
import Text from "@app/components/questkit/text";
import { Analytics } from "@app/analytics";
import { KioskStartView } from "./KioskStartView";
import { ScheduleStartView } from "@app/quest/start/ScheduleStartView";
import { PublicSubmissionsStartView } from "@app/quest/start/PublicSubmissionsStartView";
import { ChooseQuestStartTriggerView } from "@app/quest/start/ChooseQuestStartTriggerView";
import { View } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { InfoText } from "@app/components/questkit/InfoText";
import { AddEntryCard } from "@app/quest/edit/AddEntryCard";
import { QuestStartTriggerEditData } from "@app/store/cache/questPrototypes";
import { ManualStartView } from "@app/quest/start/ManualStartView";
import { UserListSyncKey } from "@app/components/questkit/UserList/UserList.controller";
import { updateQuestStartTrigger } from "@app/util/client/requests/questStartTriggers";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { sentry } from "@app/util/sentry";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { QuestStartTriggerType } from "@questmate/openapi-spec";

interface QuestStartTriggerProps {
  startTrigger: QuestStartTriggerEditData;
  isChangingQuestType: boolean;
  setIsChangingQuestType: (isChangingQuestType: boolean) => void;
  openStartQuestModal?: () => void;
  readOnly?: boolean;
  assigneeSynchronizationKey?: UserListSyncKey;
}
export const QuestStartTrigger: React.FC<QuestStartTriggerProps> = ({
  startTrigger,
  isChangingQuestType,
  setIsChangingQuestType,
  openStartQuestModal,
  assigneeSynchronizationKey,
  readOnly = false,
}) => {
  const startChangingQuestType = useCallback(() => {
    Analytics.trackEvent("Start Changing Quest Type", {
      currentQuestType: startTrigger.type,
    });
    setIsChangingQuestType(true);
  }, [startTrigger.type, setIsChangingQuestType]);
  const onCancelChangeQuestType = useCallback(() => {
    Analytics.trackEvent("Cancel Changing Quest Type");
    setIsChangingQuestType(false);
  }, [setIsChangingQuestType]);

  const { questPrototypeId } = useQuestViewContext(["MANAGE", "PREVIEW"]);
  const snackbar = useContext(SnackbarContext);
  const onQuestTypeSelected = useCallback(
    (newType: QuestStartTriggerType) => {
      Analytics.trackEvent("Choose Quest Type", {
        questPrototypeId: questPrototypeId,
        questType: newType,
      });
      return updateQuestStartTrigger(questPrototypeId, startTrigger.id, {
        type: newType,
      })
        .then(() => {
          setIsChangingQuestType(false);
        })
        .catch((e) => {
          console.error("Failed to update Quest Type", e);
          snackbar.sendMessage(
            `Failed to update Quest Type. Please try again later.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e);
        });
    },
    [questPrototypeId, setIsChangingQuestType, snackbar, startTrigger.id]
  );
  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) {
      setIsChangingQuestType(false);
    }
  }, [isFocused, setIsChangingQuestType]);

  return (
    <View testID="quest-start-trigger">
      {isChangingQuestType ? (
        <ChooseQuestStartTriggerView
          context="CHANGE_TYPE"
          onQuestTypeSelected={onQuestTypeSelected}
          onCancelChangeQuestType={onCancelChangeQuestType}
          currentType={startTrigger.type}
        />
      ) : (
        <>
          {startTrigger.type === null ? (
            <>
              <AddEntryCard
                text="Add Start Settings"
                icons={["kiosk", "play", "link", "schedule-send"]}
                onPress={startChangingQuestType}
                testID="add-quest-start-trigger-button"
              />
              <StyledInfoText
                text="Please add start settings to use this Quest!"
                infoType="warning"
              />
            </>
          ) : startTrigger.type === "KIOSK" ? (
            <KioskStartView
              startTrigger={startTrigger}
              startChangingQuestType={startChangingQuestType}
              openStartQuestModal={openStartQuestModal!}
              readOnly={readOnly}
            />
          ) : startTrigger.type === "SCHEDULE" ? (
            <ScheduleStartView
              startTrigger={startTrigger}
              startChangingQuestType={startChangingQuestType}
              openStartQuestModal={openStartQuestModal!}
              readOnly={readOnly}
            />
          ) : startTrigger.type === "PUBLIC" ? (
            <PublicSubmissionsStartView
              startTrigger={startTrigger}
              startChangingQuestType={startChangingQuestType}
              openStartQuestModal={openStartQuestModal!}
              readOnly={readOnly}
            />
          ) : startTrigger.type === "BASIC" ? (
            <ManualStartView
              startTrigger={startTrigger}
              startChangingQuestType={startChangingQuestType}
              openStartQuestModal={openStartQuestModal!}
              readOnly={readOnly}
              assigneeSynchronizationKey={assigneeSynchronizationKey}
            />
          ) : (
            <View>
              <Text size="medium" $warning={true}>
                It seems there&apos;s something new to see here. Please update /
                reload your app to experience some new magics!
              </Text>
            </View>
          )}
        </>
      )}
    </View>
  );
};

const StyledInfoText = styled(InfoText)`
  margin-horizontal: 20px;
  margin-bottom: 16px;
  margin-top: 0;
  align-self: center;
`;
export const Section = styled.View`
  margin-bottom: 20px;
`;
