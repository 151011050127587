import { QuestmateTheme } from "@app/themes/QuestmateTheme";
import { colors } from "./Colors";

export const lightTheme: QuestmateTheme = {
  background: colors.white,
  primary: colors.d5_darkPrimary,
  warning: colors.red500,
  action: colors.green200,
  inactive: colors.neutral400,
  modal: colors.white,
  cardInCard: colors.neutral200,
  headerText: colors.white,
  headerBackground: colors.primary700,
  card: {
    background: colors.neutral100,
    text: colors.blue950,
  },
  startQuestButton: {
    actionBarBackground: colors.green50,
  },
  button: {
    primary: {
      background: colors.primary700,
      text: colors.white,
    },
    secondary: {
      background: colors.white,
      text: colors.d5_darkPrimary,
      border: colors.d4_darkPrimary40,
    },
    warning: {
      background: colors.red500,
      text: colors.white,
    },
    highlight: {
      background: colors.yellow300,
      text: colors.d5_darkPrimary,
    },
    action: {
      background: colors.green200,
      text: colors.blue950,
    },
  },
  tile: {
    standard: {
      text: colors.blue950,
      background: { normal: colors.primary50, hover: colors.d7_primary50Hover },
      border: undefined,
      infoPill: {
        background: colors.green50,
        border: undefined,
        text: colors.blue950,
      },
    },
    assignment: {
      text: colors.blue950,
      background: { normal: colors.green50, hover: colors.d6_green50Hover },
      border: undefined,
      infoPill: {
        background: colors.green100,
        border: colors.green700,
        text: colors.blue950,
      },
    },
    create: {
      text: colors.white,
      background: {
        normal: colors.primary800,
        hover: colors.d8_primary800Hover,
      },
      border: undefined,
      infoPill: {
        border: undefined,
        background: colors.white,
        text: colors.primary700,
      },
    },
  },
  questRunCard: {
    text: colors.blue950,
    background: { normal: colors.neutral100, hover: colors.neutral200 },
    border: undefined,
  },
  interaction: {
    primary: colors.primary700,
    secondary: colors.white,
    neutralStrong: colors.neutral500,
    neutralMedium: colors.neutral300,
    neutralLight: colors.neutral100,
  },
  textInput: {
    normal: {
      text: colors.d5_darkPrimary,
      background: colors.white,
      border: colors.d4_darkPrimary20,
      placeholder: colors.d4_darkPrimary40,
      selection: colors.primary500,
    },
    focused: {
      text: colors.d5_darkPrimary,
      background: colors.white,
      border: colors.primary500,
      placeholder: colors.d4_darkPrimary30,
      selection: colors.primary500,
    },
  },
  progressBar: {
    fill: colors.primary800,
    background: colors.d9_primary800_20,
  },
  scrollShadow: [
    "rgba(229, 229, 229, 0.18)",
    "rgba(206, 201, 201, 0.6)",
    "rgba(206, 201, 201, 0.9)",
  ],
  sideBar: {
    scrollShadow: [
      "rgba(81, 30, 173, 0.10)",
      "rgba(81, 30, 173, 0.4)",
      "rgba(81, 30, 173, 0.6)",
    ],
    scrollerBackground: colors.blue950,
    background: colors.blue950,
    text: colors.neutral200,
    assignmentCounter: {
      background: colors.green100,
      text: colors.blue950,
    },
    item: {
      inactive: {
        background: "transparent",
        text: colors.neutral300,
      },
      active: {
        background: colors.d3_blurple,
        text: colors.white,
      },
      hover: {
        background: "transparent", // background, // add back when we are also ready to add margin-bottom: 4px;
        text: colors.white,
      },
    },
  },
  actionBar: {
    background: colors.neutral100,
  },
  questView: {
    sectionHeader: {
      description: colors.neutral500,
    },
    sectionDivider: colors.neutral300,
  },
  chat: {
    icon: {
      background: colors.yellow700,
    },
    bubble: {
      background: {
        user: colors.neutral900,
        other: colors.primary700,
      },
    },
  },
  statusBarStyle: "dark-content",
  colorScheme: "light",
};

export const darkTheme = lightTheme;

// export const darkTheme: QuestmateTheme = {
//   background: colors.d1_darkBlurple,
//   warning: colors.red400,
//   primary: colors.white,
//   action: colors.green200,
//   inactive: colors.neutral300,
//   modal: colors.primary700,
//   headerText: colors.white,
//   headerBackground: colors.primary800,
//   cardInCard: colors.primary800,
//   tile: {
//     text: colors.white,
//     background: colors.d2_darkBlurpleLurple,
//     infoPill: {
//       background: colors.neutral300,
//       text: colors.d2_darkBlurpleLurple,
//     },
//   },
//   button: {
//     primary: {
//       background: colors.white,
//       text: colors.blue950,
//     },
//     warning: {
//       background: colors.red400,
//       text: colors.white,
//     },
//     action: {
//       background: colors.green200,
//       text: colors.blue950,
//     },
//   },
//   textInput: {
//     normal: {
//       background: colors.d2_darkBlurpleLurple,
//       text: colors.neutral200,
//     },
//     focused: {
//       background: colors.white,
//       text: colors.blue950,
//     },
//   },
//   sideBar: {
//     scrollerBackground: colors.blue950,
//     background: colors.blue950,
//     text: colors.neutral200,
//     item: {
//       inactive: {
//         background: "transparent",
//         text: colors.neutral300,
//       },
//       active: {
//         background: colors.d3_blurple,
//         text: colors.white,
//       },
//       hover: {
//         background: "transparent", // background, // add back when we are also ready to add margin-bottom: 4px;
//         text: colors.white,
//       },
//     },
//     // activeItemBackground: colorsNew.primary700,
//     // activeItemText: colorsNew.white,
//   },
//
//   chat: {
//     bubble: {
//       background: {
//         user: colors.neutral200,
//         other: colors.white,
//       },
//     },
//   },
//   statusBarStyle: "light-content",
//   colorScheme: "dark",
// };
