import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components/native";
import { Text } from "@app/components/questkit";
import { QuestDataPickerComponentModel } from "@app/components/item/components/custom/types";
import { useItems } from "@app/quest/edit/items";
import { sentry } from "@app/util/sentry";
import {
  isContextIdentifier,
  isExpressionIdentifier,
  isItemIdentifier,
  QuestDataContextIdentifier,
  QuestDataExpressionIdentifier,
  QuestDataIdentifierSchema,
  QuestDataItemIdentifier,
} from "@questmate/questscript";
import ListItem from "@app/components/questkit/listItem";
import { CheckboxToggle } from "@app/components/item/components/checkbox";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { ExpressionInput } from "@app/components/item/components/custom/components/ExpressionInput";
import {
  type StandardDropdownListItem,
  StandardInlineDropdown,
} from "@app/components/questkit/dropdown/StandardInlineDropdown";

interface CustomItemQuestDataPickerComponentProps {
  component: QuestDataPickerComponentModel;
  readOnly: boolean;
}

export const CustomItemQuestDataPickerComponent: React.FC<
  CustomItemQuestDataPickerComponentProps
> = ({ component, readOnly }) => {
  const { items } = useItems();

  const standardOptions: StandardDropdownListItem<string>[] = useMemo(() => {
    const itemOptions = items.map((item) => {
      // Just the unique id of the item, not including the `prototypeId` which changes on clone or Quest start.
      const stableItemId = item.prototype.id.split(":")[1];
      return {
        icon: "item" as const,
        value: JSON.stringify({ itemId: stableItemId }),
        label: item.prototype.name,
      };
    });

    return [
      {
        icon: "clock" as const,
        value: JSON.stringify({ contextKey: "completedAt" }),
        label: "Quest Completed Timestamp",
      },
      {
        icon: "person" as const,
        value: JSON.stringify({ contextKey: "submittedBy" }),
        label: "Quest Submitter",
      },
      ...itemOptions,
    ];
  }, [items]);

  const onSelect = component.onSelect;
  const setSelectedOption = useCallback(
    (selectedOption?: StandardDropdownListItem<string>) => {
      if (selectedOption?.value) {
        try {
          const parsedValue = JSON.parse(selectedOption.value);
          const dataIdentifier = QuestDataIdentifierSchema.parse(parsedValue);
          onSelect(dataIdentifier);
          return;
        } catch (e) {
          console.error(
            "Failed to parse selectedOption.value, it should have been a valid JSON object!",
            selectedOption,
            e
          );
          sentry.captureException(e, {
            extra: {
              message:
                "Failed to parse selectedOption.value, it should have been a valid JSON object!",
              selectedOption,
            },
          });
        }
      }
      onSelect(null);
    },
    [onSelect]
  );

  const value = component.value;
  const selectedOption = useMemo(() => {
    if (value && typeof value === "object") {
      // We need a string value for the dropdown component
      // Filter out any additional fields that might be present in the object
      return JSON.stringify({
        // `undefined` is filtered out when stringified
        itemId: (value as QuestDataItemIdentifier).itemId,
        contextKey: (value as QuestDataContextIdentifier).contextKey,
        exp: (value as QuestDataExpressionIdentifier).exp,
      });
    }
    return undefined;
  }, [value]);

  const onChangeSelectedDataPath = useCallback(
    (selectedDataPath: string | undefined) => {
      if (!value || isContextIdentifier(value)) {
        return;
      }
      onSelect(JSON.parse(JSON.stringify({ ...value, selectedDataPath })));
    },
    [onSelect, value]
  );

  // const [customOption, setCustomOption] = useState<null | DropdownOption>(null);
  // const onSearchTextChange = useCallback((searchText: string) => {
  //   const match = /\{\{(.*?)}}/.exec(`${searchText}`);
  //   if (match) {
  //     setCustomOption({
  //       icon: "terminal-prompt",
  //       label: `Expression: '${match[1]}'`,
  //       value: JSON.stringify({ exp: match[0] }),
  //       searchTerms: [match[0]],
  //     });
  //   } else {
  //     setCustomOption(null);
  //   }
  // }, []);

  const allOptions = useMemo(() => {
    const exp = (isExpressionIdentifier(value) && value.exp) || "";
    return [
      {
        icon: "terminal-prompt" as const,
        label: `Custom Text`,
        value: JSON.stringify({ exp }),
        searchTerms: [exp],
      },
      ...standardOptions,
    ];
  }, [standardOptions, value]);

  const onChangeExpression = useCallback(
    (exp: string) => {
      onSelect({ exp });
    },
    [onSelect]
  );

  return (
    <>
      {component.title ? (
        <CustomComponentTitle size="medium">
          {component.title}
        </CustomComponentTitle>
      ) : null}
      <StandardInlineDropdown
        optionNoun={"Quest Data"}
        optionPluralNoun={"Quest Data"}
        placeholderIcon={"item"}
        options={allOptions}
        value={selectedOption}
        onSelect={setSelectedOption}
        // onSearchTextChange={onSearchTextChange}
        readOnly={readOnly}
        loadingOptions={component.loading}
      />
      {isExpressionIdentifier(value) ? (
        <ExpressionInput value={value.exp} onChange={onChangeExpression} />
      ) : isItemIdentifier(value) ? (
        // TODO: Migrate these to expressions?
        <ItemDataPathSelector
          itemDataIdentifier={value}
          onChange={onChangeSelectedDataPath}
        />
      ) : null}
    </>
  );
};

type CustomItemDataKeySelectorProps = {
  itemDataIdentifier: QuestDataItemIdentifier;
  onChange: (selectedDataPath: string | undefined) => void;
};
export const ItemDataPathSelector: React.FC<CustomItemDataKeySelectorProps> = ({
  itemDataIdentifier,
  onChange: _onChange,
}) => {
  const itemId = itemDataIdentifier.itemId;
  const { items } = useItems();
  const item = useMemo(
    () =>
      items.find((item) => {
        const stableItemId = item.prototype.id.split(":")[1];
        return stableItemId === itemId;
      }),
    [items, itemId]
  );

  const onChangeDebounced = useMemo(
    () => AwesomeDebouncePromise(_onChange, 1000),
    [_onChange]
  );

  const clearSelectedDataPath = useCallback(() => {
    setDataPathText("");
    onChangeDebounced(undefined);
  }, [onChangeDebounced]);

  const [dataPathText, setDataPathText] = useState(
    itemDataIdentifier.selectedDataPath || ""
  );

  const setDataPath = useCallback(
    (newDataPathText: string) => {
      setDataPathText(newDataPathText);
      onChangeDebounced(newDataPathText);
    },
    [onChangeDebounced]
  );

  const isDefaultPath = !dataPathText;

  return item?.prototype.type === "CustomV2" ? (
    <ItemDataPathSelectorContainer>
      <ListItem
        text={'Default "stringValue"'}
        disabled={false}
        onPress={clearSelectedDataPath}
        actionComponent={
          <CheckboxToggle
            checked={isDefaultPath}
            action={clearSelectedDataPath}
          />
        }
      />
      <ListItem
        editable={true}
        placeholder={"Enter data path"}
        text={dataPathText}
        onTextChange={setDataPath}
        disabled={false}
        actionComponent={
          <CheckboxToggle checked={!isDefaultPath} action={doNothing} />
        }
      />
    </ItemDataPathSelectorContainer>
  ) : null;
};

const doNothing = () => undefined;

const ItemDataPathSelectorContainer = styled.View`
  margin-left: 44px;
`;

const CustomComponentTitle = styled(Text)`
  margin-bottom: 10px;
`;
