import * as React from "react";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import { reduxPersistor, store } from "@app/store";
import { StackActions } from "@react-navigation/native";
import {
  CombinedRoutesParamList,
  QMStackParamList,
} from "@app/navigation/QMNavigator";
import { setLoggingOut } from "@app/store/UI";
import { Platform } from "react-native";
import {
  EventListenerCallback,
  EventMapCore,
} from "@react-navigation/core/src/types";
import { NavigationState } from "@react-navigation/routers/src/types";
import { sentry } from "@app/util/sentry";
import { getPathForScreen } from "@app/navigation/linkingConfig";
import styled from "styled-components/native";
import * as AuthSession from "expo-auth-session";
import { userLogout } from "@app/store/auth";
import {
  retrieveRefreshToken,
  storeRefreshToken,
} from "@app/store/persistConfiguration";
import { Auth0LogoutGlobalRef } from "@app/authentication/Auth0Context";

import { mutate } from "swr";
import { Analytics } from "@app/analytics";
import axios from "axios";
import { abortRequests } from "@app/util/client";
import { ENV } from "@app/config/env";

/**
 * This screen is shared between the logged-in and logged-out navigation stacks.
 * This allows us to navigate here while transitioning between the two stacks and not perform
 * any undesired renders of screens before or after the transition from logged-in to logged-out stacks.
 */
export const LogoutScreen = (): React.ReactElement => <BlankView />;

const BlankView = styled.View`
  background-color: white;
`;

export const logOut = <RouteName extends keyof QMStackParamList>(options: {
  reason: string;
  screenName?: RouteName;
  screenParams?: QMStackParamList[RouteName];
}): void => {
  if (store.getState().ui.loggingOut) {
    console.warn("logOut called while logging out. Ignoring.");
    return;
  }
  Analytics.trackEvent("Log Out", {
    reason: options.reason,
    redirectScreen: options.screenName,
  });

  const scope = sentry.getCurrentHub().getScope();
  const parentSpan = scope?.getSpan();
  const span = parentSpan?.startChild({
    op: "logOut",
    description: "Logging out of application",
    status: "in_progress",
    data: {
      reason: options.reason,
    },
  });
  scope.addBreadcrumb({
    message: "Logout Initiated",
    level: "info",
    data: options,
  });

  store.dispatch(setLoggingOut(true));
  const logoutState = {
    step: "POPPING_TO_TOP",
    failsafeTimeout: undefined as undefined | NodeJS.Timeout,
  };

  const onLogoutDone = (error?: Error) => {
    const lastStepReached = logoutState.step;
    logoutState.step = "DONE";
    clearTimeout(logoutState.failsafeTimeout);
    navigationRef.removeListener("state", logoutStepsManager);
    if (error) {
      span?.setStatus("failed");
      const eventId = sentry.captureException(error, {
        extra: {
          lastStepReached,
          rootNavigationState: navigationRef.getRootState(),
        },
      });
      console.warn(`${error.message} Sentry Event Id:`, eventId);
      const fallbackScreenName = navigationRef
        .getRootState()
        .routeNames.includes("Login")
        ? "Login"
        : "Home";
      navigationRef.resetRoot({
        index: 0,
        routes: [{ name: fallbackScreenName }],
      });
    } else {
      span?.setStatus("completed");
      scope.addBreadcrumb({ message: "Logout Complete" });
    }
    store.dispatch(setLoggingOut(false));
    span?.finish();
  };

  const failSafeErrorWithStack = new Error(
    "Logout failsafe triggered. Did not complete logout process within 6 seconds."
  );
  const restartFailSafeTimeout = () => {
    clearTimeout(logoutState.failsafeTimeout);
    logoutState.failsafeTimeout = setTimeout(() => {
      // This should never happen, but if it does, we want to fail gracefully and report it to Sentry.
      if (logoutState.step !== "DONE") {
        onLogoutDone(failSafeErrorWithStack);
      }
    }, 6000);
  };
  restartFailSafeTimeout();

  const logoutStepsManager: EventListenerCallback<
    EventMapCore<NavigationState<CombinedRoutesParamList>>,
    "state"
  > = (event) => {
    const state = event.data.state ?? {};
    switch (logoutState.step) {
      case "POPPING_TO_TOP":
        if (state.routes?.length === 1) {
          // popToTop completed

          logoutState.step = "NAVIGATING_TO_LOGOUT_SCREEN";
          scope.addBreadcrumb({
            message: "Logout state transitioned to NAVIGATING_TO_LOGOUT_SCREEN",
            level: "debug",
          });
          // must replace home route with logout route to avoid home screen hooks running while logging out.
          navigationRef.dispatch(StackActions.replace("Logout"));
        }
        break;
      case "NAVIGATING_TO_LOGOUT_SCREEN":
        if (state.routes?.[state.index]?.name === "Logout") {
          // navigating to logout screen completed
          logoutState.step = "LOGGING_OUT";
          scope.addBreadcrumb({
            message: "Logout state transitioned to LOGGING_OUT",
            level: "debug",
          });

          abortRequests("Logging out.");
          scope.addBreadcrumb({
            message: "Requests Aborted.",
            level: "debug",
          });

          store.dispatch(userLogout());
          scope.addBreadcrumb({
            message: "Logout Dispatched.",
            level: "debug",
          });

          try {
            AuthSession.dismiss();
          } catch (_e) {
            // ignore
          }
          scope.addBreadcrumb({
            message: "AuthSession dismissed.",
            level: "debug",
          });

          // Clear SWR request cache
          void clearSWRCache();
          scope.addBreadcrumb({
            message: "SWR Cache clear requested.",
            level: "debug",
          });

          restartFailSafeTimeout();
          void retrieveRefreshToken()
            .catch((e) => {
              sentry.captureException(e, {
                extra: {
                  message: "Error retrieving refresh token to invalidate it.",
                },
              });
              return null;
            })
            .then((refreshToken) => {
              scope.addBreadcrumb({
                message: `Refresh token found in storage: ${Boolean(
                  refreshToken
                )}`,
                level: "debug",
              });
              if (refreshToken) {
                restartFailSafeTimeout();
                const { auth0ClientId, auth0BaseUrl } = ENV;
                return new Promise<boolean>((resolve) => {
                  try {
                    // Note: This is wrapped in a try-catch code block to also catch errors that
                    //       occur synchronously before the request is sent.
                    axios
                      .post(
                        `${auth0BaseUrl}/oauth/revoke/`,
                        {
                          client_id: auth0ClientId,
                          token: refreshToken,
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                      .then(() => {
                        resolve(true);
                      })
                      .catch((e) => {
                        const sentryEventId = sentry.captureException(e, {
                          extra: {
                            message:
                              "Error initiating invalidation of refresh token.",
                          },
                        });
                        sentry.addBreadcrumb({
                          message:
                            "Error initiating invalidation of refresh token.",
                          level: "debug",
                          data: {
                            sentryEventId,
                          },
                        });
                        resolve(false);
                      });
                  } catch (e) {
                    const sentryEventId = sentry.captureException(e, {
                      extra: {
                        message: "Error invalidating refresh token.",
                      },
                    });
                    sentry.addBreadcrumb({
                      message: "Error invalidating refresh token.",
                      level: "debug",
                      data: {
                        sentryEventId,
                      },
                    });
                    resolve(false);
                  }
                }).then((success) => {
                  sentry.addBreadcrumb({
                    message: `Refresh token invalidation completed. Success=${success}.`,
                    level: "debug",
                  });
                });
              } else {
                sentry.captureMessage(
                  "NOTICE: Refresh token not found during logout, unable to revoke!"
                );
              }
            })
            .then(async () => {
              restartFailSafeTimeout();
              await Promise.all([
                reduxPersistor.flush().catch((e) =>
                  sentry.captureException(e, {
                    extra: { message: "Error flushing redux persistor." },
                  })
                ),
                storeRefreshToken(undefined).catch((e) =>
                  sentry.captureException(e, {
                    extra: { message: "Error clearing refresh token." },
                  })
                ),
              ]);
              scope.addBreadcrumb({
                message: `Cleared persisted redux state and refresh token.`,
                level: "debug",
              });
            })
            .then(() => {
              if (Platform.OS === "web") {
                // ensure any sentry events were captured before navigating away.
                restartFailSafeTimeout();
                return Promise.resolve(sentry.flush(5000)).catch((e) => {
                  console.error(
                    "Failed to flush sentry before redirecting to Auth0 to logout. Events may be lost...",
                    e
                  );
                  // continue anyway
                });
              }
            })
            .then(() => {
              restartFailSafeTimeout();
              let auth0LogoutRedirectPath: undefined | string = undefined;
              if (options.screenName) {
                const screenName = options.screenName;
                const screenParams = options.screenParams || {};
                auth0LogoutRedirectPath = getPathForScreen(
                  {
                    screen: screenName,
                    params: screenParams,
                  },
                  screenName === "Login"
                    ? { pathConfigurationOverride: "UNAUTHENTICATED" }
                    : undefined
                );
              }
              scope.addBreadcrumb({
                message: "Redirecting to Auth0 logout...",
                level: "debug",
                data: { auth0LogoutRedirectPath },
              });

              return Auth0LogoutGlobalRef.current(
                auth0LogoutRedirectPath
                  ? getPathForScreen({
                      screen: "Redirect",
                      params: {
                        url: auth0LogoutRedirectPath,
                      },
                    })
                  : undefined
              )
                .catch((e) => {
                  const eventId = sentry.captureException(e, {
                    extra: { message: "Error performing Auth0 logout." },
                  });
                  scope.addBreadcrumb({
                    message: "Error when performing Auth0 logout.",
                    level: "error",
                    data: { auth0LogoutRedirectPath, sentryEventId: eventId },
                  });
                })
                .then(() => {
                  // This is the last step for WEB since redirecting after logout will be handled by the redirect url we provide to Auth0 Logout
                  if (Platform.OS === "web") {
                    onLogoutDone();
                  } else {
                    restartFailSafeTimeout();
                    logoutState.step = "SWITCHING_TO_LOGGED_OUT_NAVIGATOR";
                    scope.addBreadcrumb({
                      message:
                        "Logout state transitioned to SWITCHING_TO_LOGGED_OUT_NAVIGATOR",
                      level: "debug",
                    });
                    // trigger a state change to progress the logout state machine in case the logged-out navigator is already active
                    navigationRef.setParams({});
                  }
                });
            });
        }
        break;
      case "SWITCHING_TO_LOGGED_OUT_NAVIGATOR": // NATIVE ONLY
        if (state.routeNames?.includes("Login")) {
          // switching to logged-out navigation stack completed
          logoutState.step = "NAVIGATING_TO_POST_LOGOUT_SCREEN";
          scope.addBreadcrumb({
            message:
              "Logout state transitioned to NAVIGATING_TO_POST_LOGOUT_SCREEN",
            level: "debug",
          });
          navigationRef.dispatch(
            StackActions.replace(
              options.screenName || "Login",
              options.screenParams || {}
            )
          );
        }
        break;
      case "NAVIGATING_TO_POST_LOGOUT_SCREEN": // NATIVE ONLY
        if (
          state.routes?.[state.index ?? 0]?.name ===
          (options.screenName || "Login")
        ) {
          // navigating to post logout screen completed
          onLogoutDone();
        }
        break;
    }
  };
  navigationRef.addListener("state", logoutStepsManager);

  // This fixes issues with browser back/forward buttons and removes
  // the logged-in history from the browser back stack
  if (navigationRef.getRootState().routes.length !== 1) {
    // popToTop appears to fail when the navigation stack has only one route
    navigationRef.dispatch(StackActions.popToTop());
  } else {
    // skip to the next step if we do not need to popToTop
    logoutState.step = "NAVIGATING_TO_LOGOUT_SCREEN";
    scope.addBreadcrumb({
      message: "Logout state transitioned to NAVIGATING_TO_LOGOUT_SCREEN",
      level: "debug",
    });
    // must replace home route with logout route to avoid home screen hooks running while logging out.
    navigationRef.dispatch(StackActions.replace("Logout"));
  }
};

export const clearSWRCache = async () => {
  await mutate(/* match all keys */ () => true, undefined, {
    revalidate: false,
  });
};
