import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { AppState } from "@app/store";
import {
  TemplateListItem,
  TemplateDetails,
  QuestPrototypeDetail,
  RecentQuestsListItem,
  LibraryTemplateListItem,
  AssignmentListItem,
  type LibraryTemplateDetails,
} from "@questmate/openapi-spec";
import { createDataMapper } from "@app/store/cache/DataMapper";
import { userLogout, selectLoggedInUserId } from "@app/store/auth";
import { questPrototypeLoaded } from "@app/store/cache/questPrototypes";
import { selectUsersWorkspaceIds } from "@app/store/cache/workspaceMemberships";
import { assignmentsLoaded } from "@app/store/cache/assignments";
import { UnionToIntersection } from "@questmate/common";
import { questInstanceLoaded } from "@app/store/cache/questInstances";

type Quest = Omit<
  UnionToIntersection<APIQuest>,
  "currentFormPrototype" | "currentQuestPrototype" | "owner" | "workspace"
> & {
  currentQuestPrototypeId?: string | null;
  ownerId?: string;
  workspaceId?: string | null;
};

const questAdapter = createEntityAdapter<Quest>({});

export const { selectById: selectQuestById, selectAll: selectAllQuests } =
  questAdapter.getSelectors<AppState>((state) => state.cache.quests);

/**
 * These are Quests a user is the admin of, they are in one of their workspaces or are owned by them.
 */
export const selectUsersQuests = createSelector(
  [
    selectAllQuests,
    (state) => selectUsersWorkspaceIds(state),
    (state) => selectLoggedInUserId(state),
  ],
  (quests, workspaceIds, loggedInUserId) => {
    return quests.filter((quest) => {
      const questInOneOfUsersWorkspaces =
        !!quest?.workspaceId && workspaceIds.includes(quest.workspaceId);

      // do not show other Quests in a "personal" workspace that are not ours (can happen when previewing a template)
      const userOwnsQuest =
        quest?.ownerId !== undefined && quest.ownerId === loggedInUserId;

      return questInOneOfUsersWorkspaces || userOwnsQuest;
    });
  }
);

const slice = createSlice({
  name: "cache/quests",
  initialState: questAdapter.getInitialState(),
  reducers: {
    questsLoaded: (
      state,
      action: PayloadAction<
        (TemplateListItem | RecentQuestsListItem | LibraryTemplateListItem)[]
      >
    ) =>
      questAdapter.upsertMany(
        state,
        action.payload.map((quest) =>
          mapQuest({
            ...quest,
            ...(quest as TemplateListItem)?.currentQuestPrototype?.quest,
          })
        )
      ),
    questLoaded: (
      state,
      action: PayloadAction<TemplateDetails | LibraryTemplateDetails>
    ) => questAdapter.upsertOne(state, mapQuest(action.payload)),
  },
  extraReducers: (builder) => {
    builder.addCase(userLogout, (state) => questAdapter.removeAll(state));
    builder.addCase(questPrototypeLoaded, (state, action) =>
      questAdapter.upsertOne(
        state,
        mapQuest({
          ...action.payload.quest,
          ...(action.payload.status === "DRAFT" &&
          action.payload.parentItemPrototypeId === null
            ? // If the quest prototype is a draft and has no parent,
              // it is the currentQuestPrototype on the Quest
              { currentQuestPrototype: action.payload }
            : {}),
        })
      )
    );
    builder.addCase(questInstanceLoaded, (state, action) =>
      questAdapter.upsertOne(
        state,
        mapQuest({
          ...action.payload.quest,
          ...action.payload.prototype.quest,
        })
      )
    );
    builder.addCase(assignmentsLoaded, (state, action) =>
      questAdapter.upsertMany(
        state,
        action.payload.assignments
          .filter((assignment) => Boolean(assignment?.formPrototype?.quest?.id))
          .map((assignment) => mapQuest(assignment.formPrototype.quest))
      )
    );
  },
});

const reducer = slice.reducer;
export default reducer;

export const { questsLoaded, questLoaded } = slice.actions;

type APIQuest =
  | TemplateListItem
  | TemplateDetails
  | QuestPrototypeDetail["quest"]
  | Exclude<TemplateListItem["currentQuestPrototype"], null>["quest"]
  | RecentQuestsListItem
  | LibraryTemplateListItem
  | LibraryTemplateDetails
  | AssignmentListItem["formPrototype"]["quest"];

const mapQuest = createDataMapper<APIQuest, Quest>()(
  ["id", "category", "archived", "templateVisibility"],
  {
    owner: ({ id }) => (id ? { ownerId: id } : {}),
    workspace: (workspace) => ({ workspaceId: workspace?.id ?? null }),
    currentQuestPrototype: (prototype) => {
      return prototype?.id !== undefined
        ? { currentQuestPrototypeId: prototype.id }
        : {};
    },
  }
);
