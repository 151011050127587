import { createRequest, noopRequest } from "@app/util/client/requests/index";
import { AppId, Library } from "@questmate/openapi-spec";
import { store } from "@app/store";
import { questLoaded, questsLoaded } from "@app/store/cache/quests";

export const fetchLibraryQuestScripts = () => {
  return createRequest<Library.ScriptsList.ResponseBody>(
    "get",
    "/library/scripts?useTypes[]=item&useTypes[]=completion_action"
  )();
};
export const fetchLibraryQuestScript = (scriptId: string) => {
  return createRequest<Library.ScriptsDetail.ResponseBody>(
    "get",
    "/library/scripts/" + scriptId
  )();
};

export const fetchLibraryTemplates = () => {
  return createRequest<Library.TemplatesList.ResponseBody>(
    "get",
    "/library/templates"
  )((response) => {
    store.dispatch(questsLoaded(response));
  });
};

export const fetchLibraryTemplate = (questId: string | null) => {
  if (!questId) {
    return noopRequest();
  }
  return createRequest<Library.TemplatesDetail.ResponseBody>(
    "get",
    `/library/templates/${questId}`
  )((response) => {
    store.dispatch(questLoaded(response));
  });
};

export const fetchLibraryApp = (appId: AppId) => {
  return createRequest<Library.GetLibraryApp.ResponseBody>(
    "get",
    `/library/apps/${appId}`
  )();
};
